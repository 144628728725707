import * as React from "react"

function Facebook() {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M24.906 0.664158C25.1108 0.70896 25.2834 0.856066 25.2707 1.13758C25.2004 2.54499 25.1236 3.95869 24.7717 5.32773C24.8165 5.71154 24.5798 6.15928 24.1384 6.10178C20.991 5.686 19.8459 8.51995 19.4364 11.712C20.9333 11.8016 22.4175 11.9168 23.8633 12.0446C24.132 12.0702 24.2791 12.2558 24.311 12.4604C24.3878 12.5116 24.4581 12.5884 24.4901 12.7035C24.7459 13.5352 24.7523 14.4691 24.7716 15.3392C24.7844 16.0236 24.8676 16.7977 24.4069 17.3414C24.4646 17.6613 24.2918 18.0323 23.8632 18.0451C22.3279 18.0643 20.7734 18.0899 19.2124 18.1028C19.2124 18.2563 19.2124 18.4098 19.206 18.5505C19.0973 22.7278 19.2763 26.8923 19.2315 31.0632C19.2251 31.3895 18.9629 31.5109 18.7262 31.4663C18.6942 31.4854 18.6622 31.5111 18.6239 31.5238C17.837 31.7733 16.9478 31.7349 16.1291 31.7605C15.3166 31.7796 14.517 31.7988 13.7109 31.6582C13.5126 31.7221 13.2694 31.6582 13.2248 31.3959C12.5019 27.2634 12.6235 22.9006 12.6426 18.7169C12.6426 18.4546 12.6426 18.1924 12.6426 17.93C11.3696 17.8468 10.103 17.7188 8.85555 17.5398C8.66364 17.5078 8.56762 17.3798 8.55492 17.2327C8.09434 16.9257 8.20306 16.1196 8.19664 15.6143C8.17738 14.5267 8.05596 13.2666 8.36929 12.2174C8.40125 12.1087 8.47801 12.0384 8.57404 11.9871C8.63154 11.9232 8.71472 11.8784 8.82987 11.8656C10.1029 11.712 11.4143 11.6224 12.7385 11.5776C12.8024 10.375 12.9113 9.1723 13.0968 7.99531C13.9731 2.37863 18.7902 -1.03105 24.4773 0.280343C24.7141 0.337845 24.8484 0.491372 24.906 0.664158Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="31.7738"
                        height="31.7738"
                        fill="white"
                        transform="translate(0.827637)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

const MemoFacebook = React.memo(Facebook)
export default MemoFacebook
