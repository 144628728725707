import { ReactNode } from "react"
import { SystemProps } from "@storyofams/react-ui"
import styled, { keyframes } from "styled-components"

import { Shape } from "../Shape"
import ThumbsupPolish from "../Icon/library/ThumbsupPolish"
import SuitcasePolish from "../Icon/library/SuitcasePolish"
import SmileyPolish from "../Icon/library/SmileyPolish"
import FingersPolish from "../Icon/library/FingersPolish"
import FactoryPolish from "~components/common/Icon/library/FactoryPolish"
import EyePolish from "../Icon/library/EyePolish"
import Boundaries from "../Icon/library/Boundaries"
import CashewsPolish from "../Icon/library/CashewsPolish"
import Check from "../Icon/library/Check"
import CashewBunch from "../Icon/library/CashewBunch"
import Factory from "../Icon/library/Factory"
import Good from "../Icon/library/Good"
import Nuts from "../Icon/library/Nuts"
import Journey from "../Icon/library/Journey"
import Promise from "../Icon/library/Promise"

type StickerProps = {
    children?: ReactNode
    type?:
        | "boundaries"
        | "cashews"
        | "check"
        | "factory"
        | "good"
        | "journey"
        | "nuts"
        | "promise"
        | "thumbsup_polish"
        | "suitcase_polish"
        | "smiley_polish"
        | "fingers_polish"
        | "factory_polish"
        | "eye_polish"
        | "cashews_polish"
    align?: "left" | "right"
} & SystemProps

export const Sticker = ({ type = "nuts", align, ...props }: StickerProps) => {
    let stickerProps = null

    switch (type) {
        case "thumbsup_polish":
            stickerProps = { icon: ThumbsupPolish }
            break
        case "suitcase_polish":
            stickerProps = { icon: SuitcasePolish }
            break
        case "smiley_polish":
            stickerProps = { icon: SmileyPolish }
            break
        case "fingers_polish":
            stickerProps = { icon: FingersPolish }
            break
        case "factory_polish":
            stickerProps = { icon: FactoryPolish }
            break
        case "eye_polish":
            stickerProps = { icon: EyePolish }
            break
        case "cashews_polish":
            stickerProps = { icon: CashewsPolish }
            break
        case "boundaries":
            stickerProps = { icon: Boundaries }
            break
        case "check":
            stickerProps = { icon: Check }
            break
        case "cashews":
            stickerProps = { icon: CashewBunch }
            break
        case "factory":
            stickerProps = { icon: Factory }
            break
        case "good":
            stickerProps = { icon: Good }
            break
        case "journey":
            stickerProps = { icon: Journey }
            break
        case "nuts":
            stickerProps = { icon: Nuts }
            break
        case "promise":
            stickerProps = { icon: Promise }
    }

    return (
        <AnimatedSticker
            {...stickerProps}
            position="absolute"
            right={align === "right" ? [24, 50] : "unset"}
            left={align !== "right" ? [24, 50] : "unset"}
            top={[-70, -150]}
            width={["140px", "300px"]}
            height={["140px", "300px"]}
            margin="0 !important"
            className={`sticker-${align}`}
            {...props}
        />
    )
}

const translate1 = keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
`

const rotate1 = keyframes`
  0% {
    transform: rotate(-8deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(-8deg);
  }
`

const translate2 = keyframes`
  0% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-5px);
  }
`

const rotate2 = keyframes`
  0% {
    transform: rotate(8deg)
  }

  40% {
    transform: rotate(6deg)
  }

  100% {
    transform: rotate(8deg);
  }
`

const AnimatedSticker = styled(Shape)`
    &.sticker-left {
        animation: ${translate1} 10s cubic-bezier(0.45, 0, 0.55, 1) infinite;

        > div {
            animation: ${rotate1} 11s cubic-bezier(0.45, 0, 0.55, 1) infinite;
        }
    }

    &.sticker-right {
        animation: ${translate2} 10s cubic-bezier(0.45, 0, 0.55, 1) infinite;

        > div {
            animation: ${rotate2} 9s cubic-bezier(0.45, 0, 0.55, 1) infinite;
        }
    }
`
