import { WithStoryProps } from "@storyofams/storyblok-toolkit"
import { NextSeo } from "next-seo"
import { FormattedMessage } from "react-intl"
import { FooterComponent, NavigationComponent } from "~graphql/sdk"
import { Section } from "~components/common/Section"
import { Layout } from "~components/common/Layout"
import React from "react"
import { useMediaQueryContext } from "~lib/useMediaQuery"

export interface DynamicPageProps extends WithStoryProps {
    footer: FooterComponent
    navigation: NavigationComponent
    story: any
    slug?: string
}

export const DynamicPage = ({
    story,
    footer,
    navigation,
    slug,
}: DynamicPageProps) => {
    const { isMobileView } = useMediaQueryContext()

    return (
        <>
            <NextSeo
                {...story?.content?.seo}
                title={story?.content?.seo?.title ?? "Honest Cashew"}
                description={
                    story?.content?.seo?.description ?? (
                        <FormattedMessage
                            id="components.common.DynamicPage.honest"
                            defaultMessage="What makes our cashews honest?"
                        />
                    )
                }
            />
            <Layout footer={footer} navigation={navigation}>
                {story?.content?.body?.map((section, i) => {
                    if (isMobileView && section.component === "SpacerComponent")
                        return <></>

                    return (
                        <Section
                            sectionType={section.component}
                            content={section}
                            first={i === 0}
                            key={JSON.stringify(section)}
                            extraPadding={
                                story?.content?.body?.[i + 1]?.component ===
                                    "content section" &&
                                story?.content?.body?.[i + 1]?.body?.[0]
                                    ?.component === "gallery" && [100, 136]
                            }
                            extraMargin={
                                !isMobileView &&
                                slug === "farmers" && [-20, -20]
                            }
                        />
                    )
                })}
            </Layout>
        </>
    )
}
