import * as React from "react"

function Navigation() {
    return (
        <svg
            width="148"
            height="512"
            viewBox="0 0 148 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <path
                d="M13.2037 499.239C32.7988 516.443 122.84 517.276 134.438 495.434C156.688 453.537 150.216 29.3903 124.819 11.872C101.798 -4.00521 32.1615 -5.9366 13.2039 18.3401C-5.93449 42.8567 -2.79834 485.196 13.2037 499.239Z"
                fill="#000"
                fillOpacity="0.25"
            />
            <path
                d="M14.8468 493.413C33.9123 510.382 121.52 511.204 132.804 489.66C154.454 448.336 148.156 29.9885 123.446 12.7096C101.046 -2.95045 33.2922 -4.85542 14.847 19.0894C-3.77408 43.2707 -0.722713 479.562 14.8468 493.413Z"
                fill="#01A19A"
            />
        </svg>
    )
}

const MemoNavigation = React.memo(Navigation)
export default MemoNavigation
