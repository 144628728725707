import { ReactNode } from "react"
import { Icon, css } from "@storyofams/react-ui"
import { Link } from "../Link"

type SocialLinkProps = {
    href: string
    icon: ReactNode
    ariaLabel?: string
}

export const SocialLink = ({ href, ariaLabel, icon }: SocialLinkProps) => (
    <Link
        href={href}
        stylingProps={{
            css: css({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                svg: {
                    transition:
                        "0.18s transform ease-in-out, 0.18s color ease-in-out",
                },
                "&:hover svg": {
                    transform: "scale(1.1)",
                    color: "lapiz",
                },
            }),
        }}
    >
        <Icon
            aria-label={ariaLabel}
            color={"black" as any}
            as="a"
            icon={icon}
        />
    </Link>
)
