import * as React from "react"

function Journey() {
    return (
        <svg
            width="64"
            height="53"
            viewBox="0 0 64 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M59.9 11.8H49.5V4.60001C49.5 2.50001 47.8 0.800018 45.7 0.800018H19C16.9 0.800018 15.2 2.50001 15.2 4.60001V11.8H4.70002C2.60002 11.8 0.900024 13.5 0.900024 15.6V49.2C0.900024 51.3 2.60002 53 4.70002 53H59.9C62 53 63.7 51.3 63.7 49.2V15.7C63.7 13.6 62 11.8 59.9 11.8ZM21.4 7.80002C21.4 6.80002 22.2 5.90001 23.3 5.90001H41.5C42.5 5.90001 43.4 6.70002 43.4 7.80002V11.8H21.4V7.80002Z"
                fill="#8F201A"
            />
            <path
                d="M27 26.2H22.4L22.1 25.9V18.7L22.4 18.4H24.2L24.5 18.7V24.4H27.1L27.4 24.7V26L27 26.2Z"
                fill="#EDD279"
            />
            <path
                d="M30.3 26.2H28.5L28.2 25.9V18.7L28.5 18.4H30.3L30.6 18.7V25.9L30.3 26.2Z"
                fill="#EDD279"
            />
            <path
                d="M36.6 20.3H34.4V21.5H36.4L36.7 21.8V23.1L36.4 23.4H34.4V26.1L34.2 26.4H32.3L32 26.1V18.9L32.3 18.6H36.6L36.9 18.9V20.2L36.6 20.3Z"
                fill="#EDD279"
            />
            <path
                d="M42.6 26.2H38.1L37.8 25.9V18.7L38.1 18.4H42.5L42.8 18.7V20L42.5 20.3H40.2V21.3H42.3L42.6 21.6V22.9L42.3 23.2H40.2V24.6H42.6L42.9 24.9V26.2H42.6Z"
                fill="#EDD279"
            />
            <path
                d="M24.9 36.2H23.1L22.8 35.9V28.7L23.1 28.4H24.9L25.2 28.7V35.9L24.9 36.2Z"
                fill="#EDD279"
            />
            <path
                d="M29.1 36.4C28 36.4 27.3 36.2 26.8 36.1L26.5 35.8V34.4L26.8 34.2C27.2 34.3 28.5 34.5 29 34.5C29.3 34.5 29.5 34.4 29.5 34.1C29.5 33.1 26.5 33.3 26.5 30.6C26.5 29 27.7 28.3 29.3 28.3C30.2 28.3 31 28.4 31.5 28.6L31.8 28.9V30.2L31.5 30.5C31.1 30.4 30 30.2 29.5 30.2C29.1 30.2 29 30.3 29 30.6C29 31.5 32.1 31.3 32.1 34.1C31.9 35.8 30.7 36.4 29.1 36.4Z"
                fill="#EDD279"
            />
            <path
                d="M42.4 36.1L40.6 36.4L40.3 36.2L39.8 34.4H37.6L37 36.2L36.7 36.4L35.1 36.1L34.9 35.8L37.6 28.8L37.9 28.5H39.7L40 28.8L42.6 35.8L42.4 36.1ZM38.8 30.9L38.2 32.6H39.3L38.8 30.9Z"
                fill="#EDD279"
            />
            <path
                d="M9.10003 48.7L8.70003 48.8L7.50003 48.1V47.7C8.00003 47.2 8.40003 46.5 8.40003 45.5V38.8L8.70003 38.5H10.5L10.8 38.8V45.1C10.6 47 9.90003 48 9.10003 48.7Z"
                fill="white"
            />
            <path
                d="M15.6 46.4C13.1 46.4 11.8 44.9 11.8 42.4C11.8 40 13 38.3 15.7 38.3C18.3 38.3 19.5 39.9 19.5 42.3C19.4 44.9 18.2 46.4 15.6 46.4ZM15.6 40.2C14.5 40.2 14.2 41.2 14.2 42.4C14.2 43.6 14.5 44.6 15.6 44.6C16.7 44.6 17 43.6 17 42.4C17 41.2 16.7 40.2 15.6 40.2Z"
                fill="white"
            />
            <path
                d="M27.2 46.1L25.5 46.5L25.2 46.3C25.2 46.1 25 45.5 25 45.3C24.5 45.8 23.7 46.4 22.5 46.4C21.2 46.4 20.5 45.5 20.5 43.8V38.8L20.7 38.5H22.5L22.8 38.8V43.6C22.8 44.2 22.9 44.5 23.3 44.5C23.8 44.5 24.5 44.1 24.7 43.8V38.7L24.9 38.4H26.7L27 38.7V44.1C27 44.5 27.3 45.5 27.4 45.7L27.2 46.1Z"
                fill="white"
            />
            <path
                d="M34.7 46L33 46.4L32.7 46.2L31 43.2H30.8V45.9L30.5 46.2H28.7L28.4 45.9V38.7L28.7 38.4H31.7C33.6 38.4 34.6 39.1 34.6 40.6C34.6 41.9 33.7 42.4 33 42.7L34.8 45.5L34.7 46ZM31.6 40.2H30.8V41.5H31.3C31.8 41.5 32.4 41.5 32.4 40.8C32.4 40.4 32.3 40.2 31.6 40.2Z"
                fill="white"
            />
            <path
                d="M42.5 46.2H40.1L39.8 45.9L37.8 41.8L38 42.3V45.8L37.7 46.1H36L35.7 45.8V38.6L36 38.3H38.3L38.6 38.6L40.7 42.7L40.5 42.2V38.7L40.8 38.4H42.5L42.8 38.7V45.9L42.5 46.2Z"
                fill="white"
            />
            <path
                d="M49.1 46.2H44.6L44.3 45.9V38.7L44.6 38.4H49L49.3 38.7V40L49 40.3H46.7V41.3H48.8L49.1 41.6V42.9L48.8 43.2H46.7V44.6H49.1L49.4 44.9V46.2H49.1Z"
                fill="white"
            />
            <path
                d="M54.5 44.1V46L54.2 46.3H52.5L52.2 46V44.1L49.6 39.1L49.8 38.8L51.6 38.4L51.9 38.6L53.4 42.1L55 38.6L55.3 38.4L56.9 38.8L57.1 39.1L54.5 44.1Z"
                fill="white"
            />
        </svg>
    )
}

const MemoJourney = React.memo(Journey)
export default MemoJourney
