import * as React from "react"

function Logo() {
    return (
        <svg
            width="118"
            height="54"
            viewBox="0 0 118 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M113.6 53.425H3.69998C1.69998 53.425 0 51.725 0 49.725V16.225C0 14.225 1.69998 12.525 3.69998 12.525H113.5C115.5 12.525 117.2 14.225 117.2 16.225V49.725C117.2 51.725 115.6 53.425 113.6 53.425Z"
                fill="#DE773C"
            />
            <path
                d="M12.2 20.925H10.4V26.625L10.1 26.925H8.29999L8 26.625V20.925H6.19998L5.89999 20.625V19.325L6.19998 19.025H12L12.3 19.325V20.625L12.2 20.925Z"
                fill="#EDD279"
            />
            <path
                d="M19.9 26.725L18.2 27.125L17.9 26.925C17.9 26.725 17.7 26.125 17.7 25.925C17.2 26.425 16.4 27.025 15.2 27.025C13.9 27.025 13.2 26.125 13.2 24.425V19.425L13.4 19.125H15.2L15.5 19.425V24.225C15.5 24.825 15.6 25.125 16 25.125C16.5 25.125 17.2 24.725 17.4 24.425V19.325L17.6 19.025H19.4L19.7 19.325V24.725C19.7 25.125 20 26.125 20.1 26.325L19.9 26.725Z"
                fill="#EDD279"
            />
            <path
                d="M27.4 26.625L25.7 27.025L25.4 26.825L23.7 23.825H23.5V26.525L23.2 26.825H21.4L21.1 26.525V19.325L21.4 19.025H24.4C26.3 19.025 27.3 19.725 27.3 21.225C27.3 22.525 26.4 23.025 25.7 23.325L27.5 26.125L27.4 26.625ZM24.4 20.825H23.6V22.125H24.1C24.6 22.125 25.2 22.125 25.2 21.425C25.2 20.925 25 20.825 24.4 20.825Z"
                fill="#EDD279"
            />
            <path
                d="M35.3 26.825H32.9L32.6 26.525L30.6 22.425L30.8 22.925V26.425L30.5 26.725H28.8L28.5 26.425V19.225L28.8 18.925H31.1L31.4 19.225L33.5 23.325L33.3 22.825V19.325L33.6 19.025H35.3L35.6 19.325V26.525L35.3 26.825Z"
                fill="#EDD279"
            />
            <path
                d="M39.2 26.825H37.4L37.1 26.525V19.325L37.4 19.025H39.2L39.5 19.325V26.525L39.2 26.825Z"
                fill="#EDD279"
            />
            <path
                d="M47.7 26.825H45.3L45 26.525L43 22.425L43.2 22.925V26.425L42.9 26.725H41.2L40.9 26.425V19.225L41.2 18.925H43.5L43.8 19.225L45.9 23.325L45.7 22.825V19.325L46 19.025H47.7L48 19.325V26.525L47.7 26.825Z"
                fill="#EDD279"
            />
            <path
                d="M55.3 26.725C55 26.825 53.4 27.025 52.8 27.025C50.1 27.025 49 25.825 49 23.025C49 20.425 50.2 18.925 52.9 18.925C53.8 18.925 54.7 19.225 55.1 19.325L55.4 19.625V20.925L55.1 21.225C54.7 21.125 53.7 20.925 52.9 20.925C51.6 20.925 51.5 21.825 51.5 23.125C51.5 24.525 51.8 25.325 52.9 25.325C53 25.325 53.4 25.325 53.5 25.325V24.325H53L52.7 24.025V22.625L53 22.325H55.4L55.7 22.625V26.425L55.3 26.725Z"
                fill="#EDD279"
            />
            <path
                d="M9.69998 36.825H7L6.69998 36.525V29.325L7 29.025H9.69998C11.5 29.025 12.6 29.625 12.6 31.125C12.6 31.725 12.1 32.525 11.6 32.625C12.2 32.725 12.8 33.525 12.8 34.525C12.7 36.325 11.4 36.825 9.69998 36.825ZM9.59998 30.825H9V32.025H9.5C9.9 32.025 10.2 31.925 10.2 31.425C10.3 30.925 10.1 30.825 9.59998 30.825ZM9.69998 33.825H9V35.225H9.59998C9.99998 35.225 10.4 35.025 10.4 34.525C10.5 33.925 10.2 33.825 9.69998 33.825Z"
                fill="white"
            />
            <path
                d="M17.2 37.025C14.7 37.025 13.4 35.525 13.4 33.025C13.4 30.625 14.6 28.925 17.3 28.925C19.9 28.925 21.1 30.525 21.1 32.925C21 35.525 19.8 37.025 17.2 37.025ZM17.2 30.825C16.1 30.825 15.8 31.825 15.8 33.025C15.8 34.225 16.1 35.225 17.2 35.225C18.3 35.225 18.6 34.225 18.6 33.025C18.6 31.825 18.3 30.825 17.2 30.825Z"
                fill="white"
            />
            <path
                d="M28.8 36.725L27.1 37.125L26.8 36.925C26.8 36.725 26.6 36.125 26.6 35.925C26.1 36.425 25.3 37.025 24.1 37.025C22.8 37.025 22.1 36.125 22.1 34.425V29.425L22.3 29.125H24.1L24.4 29.425V34.225C24.4 34.825 24.5 35.125 24.9 35.125C25.4 35.125 26.1 34.725 26.3 34.425V29.325L26.5 29.025H28.3L28.6 29.325V34.725C28.6 35.125 28.9 36.125 29 36.325L28.8 36.725Z"
                fill="white"
            />
            <path
                d="M36.8 36.825H34.4L34.1 36.525L32.1 32.425L32.3 32.925V36.425L32 36.725H30.3L30 36.425V29.225L30.3 28.925H32.6L32.9 29.225L35 33.325L34.8 32.825V29.325L35.1 29.025H36.8L37.1 29.325V36.525L36.8 36.825Z"
                fill="white"
            />
            <path
                d="M41 36.8H38.3L38 36.5V29.3L38.3 29H41.1C43.7 29 44.8 30.6 44.8 32.7C44.8 35.1 43.6 36.8 41 36.8ZM41 30.9H40.3V35.1H41C41.9 35.1 42.3 34.3 42.3 33C42.3 31.6 42 30.9 41 30.9Z"
                fill="white"
            />
            <path
                d="M59.4647 36.575L57.7648 36.975L57.4647 36.775L55.7648 33.775H55.6647V36.475L55.3648 36.775H53.5647L53.2648 36.475V29.275L53.5647 28.975H56.5647C58.4647 28.975 59.4647 29.675 59.4647 31.175C59.4647 32.475 58.5648 32.975 57.8648 33.275L59.6647 36.075L59.4647 36.575ZM56.4647 30.775H55.6647V32.075H56.1647C56.6647 32.075 57.2648 32.075 57.2648 31.375C57.2648 30.875 57.0647 30.775 56.4647 30.775Z"
                fill="white"
            />
            <path
                d="M62.8657 36.7H61.0657L60.8657 36.5V29.3L61.1657 29H62.9657L63.2657 29.3V36.5L62.8657 36.7Z"
                fill="white"
            />
            <path
                d="M69.45 36.8H64.95L64.65 36.5V29.3L64.95 29H69.35L69.65 29.3V30.6L69.35 30.9H67.05V31.9H69.15L69.45 32.2V33.5L69.15 33.8H67.05V35.2H69.45L69.75 35.5V36.8H69.45Z"
                fill="white"
            />
            <path
                d="M73.4273 37.1C72.3273 37.1 71.6273 36.9 71.1273 36.8L70.8273 36.5V35.1L71.1273 34.9C71.5273 35 72.8273 35.2 73.3273 35.2C73.6273 35.2 73.8273 35.1 73.8273 34.8C73.8273 33.8 70.8273 34 70.8273 31.3C70.8273 29.7 72.0273 29 73.6273 29C74.5273 29 75.3273 29.1 75.8273 29.3L76.1273 29.6V30.9L75.8273 31.2C75.4273 31.1 74.3273 30.9 73.8273 30.9C73.4273 30.9 73.3273 31 73.3273 31.3C73.3273 32.2 76.4273 32 76.4273 34.8C76.2273 36.5 75.0273 37.1 73.4273 37.1Z"
                fill="white"
            />
            <path
                d="M52.3 36.6L50.5 36.9L50.2 36.7L49.7 34.9H47.5L46.9 36.7L46.6 36.9L45 36.6L44.8 36.3L47.5 29.3L47.8 29H49.6L49.9 29.3L52.5 36.3L52.3 36.6ZM48.6 31.4L48 33.1H49.1L48.6 31.4Z"
                fill="white"
            />
            <path
                d="M8.89999 46.825H7.09998L6.79999 46.625V39.425L7.09998 39.125H8.89999L9.19998 39.425V46.625L8.89999 46.825Z"
                fill="#EDD279"
            />
            <path
                d="M17.4 46.825H15L14.8 46.625L12.8 42.525L13 43.025V46.525L12.7 46.825H11L10.7 46.525V39.325L11 39.025H13.3L13.6 39.325L15.7 43.425L15.5 42.925V39.425L15.8 39.125H17.5L17.8 39.425V46.625L17.4 46.825Z"
                fill="#EDD279"
            />
            <path
                d="M24.6 40.925H22.8V46.625L22.5 46.925H20.7L20.4 46.625V40.925H18.6L18.3 40.625V39.325L18.6 39.025H24.4L24.7 39.325V40.625L24.6 40.925Z"
                fill="#EDD279"
            />
            <path
                d="M28.9 47.025C26.4 47.025 25.1 45.525 25.1 43.025C25.1 40.625 26.3 38.925 29 38.925C31.6 38.925 32.8 40.525 32.8 42.925C32.7 45.525 31.5 47.025 28.9 47.025ZM28.9 40.825C27.8 40.825 27.5 41.825 27.5 43.025C27.5 44.225 27.8 45.225 28.9 45.225C30 45.225 30.3 44.225 30.3 43.025C30.3 41.825 30 40.825 28.9 40.825Z"
                fill="#EDD279"
            />
            <path
                d="M39.3 44.525H38.8V46.625L38.5 46.925H36.7L36.4 46.625V39.425L36.7 39.125H39.8C41.7 39.125 42.7 40.125 42.7 41.725C42.7 43.525 41.4 44.525 39.3 44.525ZM39.5 40.825H38.8V42.725H39.3C39.9 42.725 40.4 42.525 40.4 41.825C40.3 41.225 40.1 40.825 39.5 40.825Z"
                fill="white"
            />
            <path
                d="M47 47.025C44.5 47.025 43.2 45.525 43.2 43.025C43.2 40.625 44.4 38.925 47.1 38.925C49.7 38.925 50.9 40.525 50.9 42.925C50.9 45.525 49.6 47.025 47 47.025ZM47 40.825C45.9 40.825 45.6 41.825 45.6 43.025C45.6 44.225 45.9 45.225 47 45.225C48.1 45.225 48.4 44.225 48.4 43.025C48.4 41.825 48.1 40.825 47 40.825Z"
                fill="white"
            />
            <path
                d="M54.4 47.025C53.3 47.025 52.6 46.825 52.1 46.725L51.8 46.425V45.025L52.1 44.825C52.5 44.925 53.8 45.125 54.3 45.125C54.6 45.125 54.8 45.025 54.8 44.725C54.8 43.725 51.8 43.925 51.8 41.225C51.8 39.625 53 38.925 54.6 38.925C55.5 38.925 56.3 39.025 56.8 39.225L57.1 39.525V40.825L56.8 41.125C56.4 41.025 55.3 40.825 54.8 40.825C54.4 40.825 54.3 40.925 54.3 41.225C54.3 42.125 57.4 41.925 57.4 44.725C57.2 46.425 56 47.025 54.4 47.025Z"
                fill="white"
            />
            <path
                d="M60.8 47.025C59.7 47.025 59 46.825 58.5 46.725L58.2 46.425V45.025L58.5 44.825C58.9 44.925 60.2 45.125 60.7 45.125C61 45.125 61.2 45.025 61.2 44.725C61.2 43.725 58.2 43.925 58.2 41.225C58.2 39.625 59.4 38.925 61 38.925C61.9 38.925 62.7 39.025 63.2 39.225L63.5 39.525V40.825L63.2 41.125C62.8 41.025 61.7 40.825 61.2 40.825C60.8 40.825 60.7 40.925 60.7 41.225C60.7 42.125 63.8 41.925 63.8 44.725C63.6 46.425 62.4 47.025 60.8 47.025Z"
                fill="white"
            />
            <path
                d="M66.9 46.825H65.1L64.8 46.625V39.425L65.1 39.125H66.9L67.2 39.425V46.625L66.9 46.825Z"
                fill="white"
            />
            <path
                d="M71.6 46.825H68.9L68.6 46.525V39.325L68.9 39.025H71.6C73.4 39.025 74.5 39.625 74.5 41.125C74.5 41.725 74 42.525 73.5 42.625C74.1 42.725 74.7 43.525 74.7 44.525C74.7 46.325 73.3 46.825 71.6 46.825ZM71.5 40.825H70.9V42.025H71.4C71.8 42.025 72.1 41.925 72.1 41.425C72.2 40.925 72.1 40.825 71.5 40.825ZM71.7 43.825H71V45.225H71.6C72 45.225 72.4 45.025 72.4 44.525C72.4 43.925 72.2 43.825 71.7 43.825Z"
                fill="white"
            />
            <path
                d="M77.8 46.825H76L75.7 46.525V39.325L76 39.025H77.8L78.1 39.325V46.525L77.8 46.825Z"
                fill="white"
            />
            <path
                d="M84.5 46.825H79.8L79.5 46.525V39.325L79.8 39.025H81.6L81.9 39.325V45.025H84.5L84.8 45.325V46.625L84.5 46.825Z"
                fill="white"
            />
            <path
                d="M87.8 46.825H86L85.7 46.525V39.325L86 39.025H87.8L88.1 39.325V46.525L87.8 46.825Z"
                fill="white"
            />
            <path
                d="M95 40.925H93.2V46.625L92.9 46.925H91.1L90.8 46.625V40.925H89L88.7 40.625V39.325L89 39.025H94.8L95.1 39.325V40.625L95 40.925Z"
                fill="white"
            />
            <path
                d="M98.1 46.825H96.3L96 46.525V39.325L96.3 39.025H98.1L98.4 39.325V46.525L98.1 46.825Z"
                fill="white"
            />
            <path
                d="M104.6 46.825H100.1L99.8 46.525V39.325L100.1 39.025H104.5L104.8 39.325V40.625L104.5 40.925H102.2V41.925H104.3L104.6 42.225V43.525L104.3 43.825H102.2V45.225H104.6L104.9 45.525V46.825H104.6Z"
                fill="white"
            />
            <path
                d="M108.5 47.025C107.4 47.025 106.7 46.825 106.2 46.725L105.9 46.425V45.025L106.2 44.825C106.6 44.925 107.9 45.125 108.4 45.125C108.7 45.125 108.9 45.025 108.9 44.725C108.9 43.725 105.9 43.925 105.9 41.225C105.9 39.625 107.1 38.925 108.7 38.925C109.6 38.925 110.4 39.025 110.9 39.225L111.2 39.525V40.825L110.9 41.125C110.5 41.025 109.4 40.825 108.9 40.825C108.5 40.825 108.4 40.925 108.4 41.225C108.4 42.125 111.5 41.925 111.5 44.725C111.3 46.425 110.1 47.025 108.5 47.025Z"
                fill="white"
            />
            <path
                d="M42.2 4.025L46.5 6.925L46.5999 7.025L54.2 10.325L57.8 11.325L61.8 13.525L69.4 16.825L73 18.025L77 20.025L84.5999 23.325L90.7 25.325L93.7 21.725L86.9 18.825L83.4 17.625L79.3 15.525L71.7 12.225L68.2 11.225L64.0999 9.025L56.5 5.725L53.0999 4.725L48.9 2.425L43.7 0.225C43 -0.075 42.3 -0.075 42 0.225L42.2 4.025Z"
                fill="#EDD279"
            />
            <path
                d="M46.5 6.92501L40.4 4.325C39.8 4.025 39.3999 3.52501 39.6999 3.22501C39.6999 3.22501 41.7 0.625006 42 0.225006L46.5 6.92501Z"
                fill="#3F5E7A"
            />
            <path
                d="M61.8 13.425L54.2 10.125L48.9 2.325L56.5 5.625L61.8 13.425Z"
                fill="#3F5E7A"
            />
            <path
                d="M69.4 16.725L64.0999 8.925L71.7 12.225L77 20.025L69.4 16.725Z"
                fill="#3F5E7A"
            />
            <path
                d="M86.8 18.725L91.0999 25.025L90.5 25.825L84.5999 23.325L79.3 15.525L86.8 18.725Z"
                fill="#3F5E7A"
            />
            <path
                d="M86.7 36.825H102.8V20.325C102.8 17.725 100.7 15.625 98.0999 15.625H91.4C88.8 15.625 86.7 17.725 86.7 20.325V36.825Z"
                fill="#EDD279"
            />
            <path
                d="M94.8 29.025C92.7 29.025 91.0999 27.325 91.0999 25.325C91.0999 23.225 92.8 21.625 94.8 21.625C96.9 21.625 98.5 23.325 98.5 25.325C98.5 27.325 96.8 29.025 94.8 29.025Z"
                fill="#3F5E7A"
            />
        </svg>
    )
}

const MemoLogo = React.memo(Logo)
export default MemoLogo
