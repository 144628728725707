import * as React from "react"

function Promise() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 73.6 73.6"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <circle fill="#795CB9" cx="36.8" cy="36.4" r="34.2" />
                </g>
                <g>
                    <path
                        fill="#EDD279"
                        d="M23,19.5h-0.6v2.1l-0.3,0.3h-1.8l-0.3-0.3v-7.2l0.3-0.3h3.1c1.9,0,2.9,1,2.9,2.6C26.3,18.5,25,19.5,23,19.5z
			 M23.1,15.9h-0.7v1.9h0.5c0.6,0,1.1-0.2,1.1-0.9C24,16.2,23.7,15.9,23.1,15.9z"
                    />
                    <path
                        fill="#EDD279"
                        d="M29.3,21.9h-1.8l-0.3-0.3v-7.2l0.3-0.3h1.8l0.3,0.3v7.2L29.3,21.9z"
                    />
                    <path
                        fill="#EDD279"
                        d="M37.8,21.9h-2.4l-0.3-0.3l-2-4.1l0.2,0.5v3.5l-0.3,0.3h-1.7l-0.3-0.3v-7.2l0.3-0.3h2.3l0.3,0.3l2.1,4.1
			L35.8,18v-3.5l0.3-0.3h1.7l0.3,0.3v7.2L37.8,21.9z"
                    />
                    <path
                        fill="#EDD279"
                        d="M41.7,21.9h-1.8l-0.3-0.3v-7.2l0.3-0.3h1.8l0.3,0.3v7.2L41.7,21.9z M46.4,21.7l-1.7,0.4l-0.3-0.2L42,18v-0.4
			l2.3-3.5l0.3-0.1l1.6,0.4l0.2,0.3l-2.2,3l2.3,3.6L46.4,21.7z"
                    />
                    <path
                        fill="#EDD279"
                        d="M51.6,19.7v1.9l-0.3,0.3h-1.8l-0.3-0.3v-1.9l-2.6-5l0.2-0.3l1.8-0.4l0.3,0.2l1.5,3.5l1.6-3.5l0.3-0.2l1.6,0.4
			l0.2,0.3L51.6,19.7z"
                    />
                </g>
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M16,29.5h-0.6v2.1l-0.3,0.3h-1.8l-0.3-0.3v-7.2l0.3-0.3h3.1c1.9,0,2.9,1,2.9,2.6C19.3,28.5,18,29.5,16,29.5z
			 M16.1,25.9h-0.7v1.9h0.5c0.6,0,1.1-0.2,1.1-0.9C17,26.3,16.7,25.9,16.1,25.9z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M26.4,31.7l-1.7,0.4l-0.3-0.2l-1.7-3h-0.2v2.7l-0.3,0.3h-1.8l-0.3-0.3v-7.2l0.3-0.3h3c1.9,0,2.9,0.7,2.9,2.2
			c0,1.3-0.9,1.8-1.6,2.1l1.8,2.8L26.4,31.7z M23.3,25.9h-0.8v1.3H23c0.5,0,1.1,0,1.1-0.7C24.1,26,24,25.9,23.3,25.9z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M30.8,32.1c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C34.7,30.5,33.5,32.1,30.8,32.1z
			 M30.9,25.9c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2c1.1,0,1.4-1,1.4-2.2C32.3,26.9,32,25.9,30.9,25.9z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M43.9,31.9h-1.7l-0.3-0.3v-3l0.2-1.1l-0.9,2.7l-0.3,0.3h-2l-0.3-0.3l-0.8-2.7l0.2,1.1v3l-0.3,0.3h-1.7
			l-0.3-0.3v-7.2l0.3-0.3h2.2l0.3,0.3l1.2,3l0.3,1.2l0.3-1.2l1.2-3l0.3-0.3h2.2l0.3,0.3v7.2L43.9,31.9z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M47.7,31.9h-1.8l-0.3-0.3v-7.2l0.3-0.3h1.8l0.3,0.3v7.2L47.7,31.9z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M52,32.1c-1.1,0-1.8-0.2-2.3-0.3l-0.3-0.3v-1.4l0.3-0.2c0.4,0.1,1.7,0.3,2.2,0.3c0.3,0,0.5-0.1,0.5-0.4
			c0-1-3-0.8-3-3.5c0-1.6,1.2-2.3,2.8-2.3c0.9,0,1.7,0.1,2.2,0.3l0.3,0.3V26l-0.3,0.3c-0.4-0.1-1.5-0.3-2-0.3
			c-0.4,0-0.5,0.1-0.5,0.4c0,0.9,3.1,0.7,3.1,3.5C54.8,31.4,53.6,32.1,52,32.1z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M60.7,31.9h-4.5L56,31.7v-7.2l0.3-0.3h4.4l0.3,0.3v1.3L60.6,26h-2.3v1h2.1l0.3,0.3v1.3l-0.3,0.3h-2.1v1.4h2.4
			l0.3,0.3v1.3L60.7,31.9z"
                    />
                </g>
                <g>
                    <defs>
                        <circle id="SVGID_1_" cx="36.8" cy="36.4" r="34.2" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlinkHref="#SVGID_1_" />
                    </clipPath>
                    <g clipPath="url(#SVGID_2_)">
                        <g>
                            <path
                                fill="#EDD279"
                                d="M47.2,44.5c-0.3-0.6-0.2-1.3,0.4-1.6l2.3-2.3l3.2-0.8l1.1-0.4c2.6-1.5,6.4,4.5,8.7,10.7
					c1.1,2.8,0.7,6.1,0.4,7.7c-0.1,0.7,0,1.4,0.3,2l1.7,3l-0.4,1.2l-9.9,5.7c-0.2,0.1-0.6,0.1-0.7-0.2l-1.9-3.3
					c-0.5-0.9-1.4-1.6-2.3-1.9c-4.3-1.6-6.9-6.1-6.9-6.1L31.9,38.5c-0.4-0.8-0.4-1.7,0.1-2.4c0,0,1.9-1.1,3,0.6l5,8.7L47.2,44.5z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M34.1,37.2l6,10.6c0.5,0.9,1.6,1.2,2.5,0.7l-6.9-12.1c-0.6-1.1-2-1.5-3-0.9c-0.3,0.2-0.5,0.4-0.7,0.6
					C32.8,36,33.7,36.4,34.1,37.2z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M54,39.1L52.4,40c4.1-2.4,7.3,4.3,9.5,10.4c1,2.8,0.7,6.1,0.4,7.7c-0.1,0.7,0,1.4,0.3,2l2.2,3.7l1.1-0.7
					c0.2-0.1,0.3-0.4,0.2-0.7l-1.9-3.3c-0.4-0.6-0.5-1.3-0.3-2c0.3-1.6,0.7-4.8-0.4-7.7C61.2,43.4,58.1,36.7,54,39.1z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M46.9,39.9l1,1.7c0,0-0.4,1.2-0.4,1.2c-0.6,0.3-0.7,1.1-0.4,1.6c0.5,0.8,1.3,1.4,2.2,1.6c0,0,0.1,0,0.1,0
					l1.6,1l1,1.7c0.6,1.1-1.1,2.1-1.4,2.1c-0.8,0.1-1.7-0.3-2.1-1.1l-4.7-8.2c-0.4-0.8-0.4-1.7,0.1-2.4C44.2,39,45.9,38.1,46.9,39.9
					z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M49.4,46.1l1.4,2.4c0.5,0.8,0.4,1.7-0.1,2.4c0.3,0,0.6-0.1,0.9-0.3c1.1-0.6,1.4-2,0.8-3l-1-1.7L50.9,46
					C50.4,46.1,49.9,46.2,49.4,46.1z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M44.7,38.6c-0.3,0.2-0.5,0.4-0.7,0.6c0.8-0.1,1.7,0.3,2.1,1.1l1.4,2.5c0,0,1.6-0.9,1.6-0.9l-1.4-2.5
					C47.1,38.4,45.7,38,44.7,38.6z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M46.1,51.9c-0.8,0.1-1.7-0.3-2.1-1.1l-3.3-5.7c-0.4-0.8-0.4-1.7,0.1-2.4c0.2-0.2,2.3-0.4,3,0.6l3.3,5.7
					C47.7,50.1,46.4,51.9,46.1,51.9z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M41.5,42L41.5,42c-0.3,0.2-0.5,0.4-0.7,0.6c0.8-0.1,1.7,0.3,2.1,1.1l3.3,5.7c0.5,0.8,0.4,1.7-0.1,2.4
					c0.3,0,0.6-0.1,0.9-0.3l0,0c1.1-0.6,1.4-2,0.8-3l-3.3-5.7C43.9,41.8,42.6,41.4,41.5,42z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M53.5,55.6c-0.7-4.9,1.8-8.3,2.7-9.4c0.3,1.1,0.2,2.2-0.3,3.3C55.2,51,54.6,53,55,55.4
					c0,0.3-0.1,0.6-0.4,0.8c-0.1,0-0.2,0.1-0.3,0.1C53.9,56.3,53.5,56.1,53.5,55.6z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M55,44.9l0.2,0.4c1,1.8-0.6,2.8-0.9,2.9c-0.8,0.1-1.7-0.3-2.1-1.1l-0.7-1.2l2.5-0.8L55,44.9z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M53.9,45l0.4,0.7c0.5,0.8,0.4,1.7-0.1,2.4c0.3,0,0.6-0.1,0.9-0.3c1.1-0.6,1.4-2,0.8-3l-0.2-0.4L53.9,45z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M49.1,41.9l-1.3-2.2C47.4,39,47.5,38,48,37.4c0.2-0.2,1.9-1.1,3,0.6l0.9,1.4l-0.4,1.2L49.1,41.9z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M48.6,36.7c-0.3,0.2-0.5,0.4-0.7,0.6c0.8-0.1,1.7,0.3,2.1,1.1l1.3,2.2l1.6-0.9l-1.3-2.2
					C51.1,36.5,49.7,36.1,48.6,36.7z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M28.5,42c0.3-0.6-1.3-0.5-1.9-0.8l-1.9-2.1l-2.9,2l-2.7,0.7c-4.1-2.4-6.1-0.1-8.4,6.1
					c-1.1,2.8-0.7,6.1-0.4,7.7c0.1,0.7,0,1.4-0.3,2L8,60.9c-0.1,0.2-0.1,0.6,0.2,0.7l9.9,5.7l1.3-0.3l1.7-3c0.5-0.9,1.4-1.6,2.3-1.9
					c4.3-1.6,6.9-6.1,6.9-6.1l9.1-16.1c1-1.8-0.6-2.8-0.9-2.9c-0.8-0.1-1.7,0.3-2.1,1.1l-3.8,6.5L28.5,42z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M39.4,37.3c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l-9.1,16.1c0,0-2.6,4.5-6.9,6.1
					c-1,0.4-1.8,1-2.3,1.9l-2.1,3.7l1.1,0.7c0.2,0.1,0.6,0.1,0.7-0.2l1.9-3.3c0.5-0.9,1.4-1.6,2.3-1.9c4.3-1.6,6.9-6.1,6.9-6.1
					l9-16.1C40.7,39.3,40.4,37.9,39.4,37.3z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M27.1,38.9c-0.8-0.1-1.7,0.3-2.1,1.1l-1.4,2.5l1.6,0.9c0.6,0.3,0.7,1.1,0.4,1.6c-0.8,1.3-2.4,2-3.8,1.5
					l-0.5-0.1l-1,1.7c-0.5,0.8-0.4,1.7,0.1,2.4c0.2,0.2,2.3,0.4,3-0.6l4.7-8.2C28.7,40.7,27.4,38.9,27.1,38.9z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M28,39.2c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l1.6,0.9C29.5,41.1,29.1,39.8,28,39.2z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M28.5,49.9l3.3-5.7c0.6-1.1-0.6-2.8-0.9-2.9c-0.8-0.1-1.7,0.3-2.1,1.1l-3.3,5.7c-0.5,0.8-0.4,1.7,0.1,2.4
					C25.7,50.8,27.9,51,28.5,49.9z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M31.7,41.6L31.7,41.6c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l1.6,0.9
					C33.1,43.6,32.8,42.2,31.7,41.6z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M17.9,44.4l-0.2,0.4c-0.5,0.8-0.4,1.7,0.1,2.4c0.2,0.2,2.3,0.4,3-0.6l0.3-0.4l-0.6-0.9L17.9,44.4z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#EDD279"
                                d="M23.2,41.2l1-1.5c0.6-1.1-0.6-2.8-0.9-2.9c-0.8-0.1-1.7,0.3-2.1,1.1L17.9,43l4.1-1.5L23.2,41.2z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#D4BC6C"
                                d="M24.2,37.1c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l1.6,0.9C25.6,39.1,25.2,37.7,24.2,37.1z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <path
                        fill="#D4BC6C"
                        d="M18.8,41.7L15.3,47c0,0-1.4-0.9,0-3c2-3,2-3,2-3S17.8,41.1,18.8,41.7z"
                    />
                </g>
            </g>
        </svg>
    )
}

const MemoPromise = React.memo(Promise)
export default MemoPromise
