import * as React from "react"

function Play() {
    return (
        <svg
            width="38"
            height="42"
            viewBox="0 0 38 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.8234 18.4425C38.0458 19.7256 38.0458 22.9333 35.8234 24.2163L5.82162 41.5379C3.59926 42.821 0.82131 41.2171 0.82131 38.651L0.821312 4.00786C0.821312 1.4417 3.59926 -0.162149 5.82161 1.12093L35.8234 18.4425Z"
                fill="#EDD279"
            />
        </svg>
    )
}

const MemoPlay = React.memo(Play)
export default MemoPlay
