import * as React from "react"

function SuitcasePolish() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 62.9 52.3"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#8D211A"
                    d="M59,11.1H48.6V3.8c0-2.1-1.7-3.8-3.8-3.8H18.1c-2.1,0-3.8,1.7-3.8,3.8v7.2H3.8c-2.1,0-3.8,1.7-3.8,3.8v33.6
		c0,2.1,1.7,3.8,3.8,3.8H59c2.1,0,3.8-1.7,3.8-3.8V14.9C62.9,12.8,61.2,11.1,59,11.1z M20.5,7.1c0-1,0.8-1.9,1.9-1.9h18.2
		c1,0,1.9,0.8,1.9,1.9v4H20.5V7.1z"
                />
                <g>
                    <path
                        fill="#EBD078"
                        d="M20.9,25.7h-5.4l-0.3-0.3v-1.3l3.1-4.5h-2.6l-0.3-0.3v-1.2l0.3-0.3h5l0.3,0.3v1.3L18,24h2.9l0.3,0.3v1.2
			L20.9,25.7z M19.3,17.7h-1.9l-0.2-0.2v-1.6l0.2-0.2h1.9l0.2,0.2v1.6L19.3,17.7z"
                    />
                    <path
                        fill="#EBD078"
                        d="M26.5,23.6v1.9l-0.3,0.3h-1.8l-0.3-0.3v-1.9l-2.6-5l0.2-0.3l1.8-0.4l0.3,0.2l1.4,3.5L27,18l0.3-0.2l1.6,0.4
			l0.2,0.3L26.5,23.6z"
                    />
                    <path
                        fill="#EBD078"
                        d="M34.7,25.6c-0.3,0.1-1.2,0.3-1.9,0.3c-2.5,0-3.6-1.2-3.6-4c0-2.7,1.2-4.1,3.8-4.1c0.8,0,1.4,0.2,1.7,0.3
			l0.3,0.3v1.3L34.6,20c-0.3-0.1-1.1-0.3-1.7-0.3c-1.2,0-1.4,0.9-1.4,2.2c0,1.4,0.2,2.2,1.3,2.2c0.5,0,1.5-0.2,1.8-0.3l0.3,0.2v1.4
			L34.7,25.6z"
                    />
                    <path
                        fill="#EBD078"
                        d="M38.1,25.7h-1.8L36,25.5v-7.2l0.3-0.3h1.8l0.3,0.3v7.2L38.1,25.7z"
                    />
                    <path
                        fill="#EBD078"
                        d="M44.6,25.7h-4.5l-0.3-0.3v-7.2l0.3-0.3h4.4l0.3,0.3v1.3l-0.3,0.3h-2.3v1h2.1l0.3,0.3v1.3l-0.3,0.3h-2.1V24
			h2.4l0.3,0.3v1.3L44.6,25.7z"
                    />
                </g>
                <g>
                    <path
                        fill="#EBD078"
                        d="M30.3,29.8h-1.8v5.7l-0.3,0.3h-1.8l-0.3-0.3v-5.7h-1.8l-0.3-0.3v-1.3l0.3-0.3h5.8l0.3,0.3v1.3L30.3,29.8z"
                    />
                    <path
                        fill="#EBD078"
                        d="M34.6,35.9c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C38.4,34.4,37.2,35.9,34.6,35.9z
			 M34.6,29.7c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2c1.1,0,1.4-1,1.4-2.2C36,30.8,35.7,29.7,34.6,29.7z"
                    />
                </g>
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M12.6,43.4H12v2.1l-0.3,0.3H9.9l-0.3-0.3v-7.2l0.3-0.3H13c1.9,0,2.9,1,2.9,2.6C15.9,42.4,14.6,43.4,12.6,43.4
			z M12.7,39.8H12v1.9h0.5c0.6,0,1.1-0.2,1.1-0.9C13.6,40.1,13.3,39.8,12.7,39.8z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M20.2,45.9c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C24.1,44.4,22.9,45.9,20.2,45.9z
			 M20.3,39.7c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2c1.1,0,1.4-1,1.4-2.2C21.7,40.8,21.4,39.7,20.3,39.7z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M28.1,45.8h-2.7l-0.3-0.3v-7.2l0.3-0.3h2.8c2.6,0,3.7,1.6,3.7,3.7C31.9,44.1,30.8,45.8,28.1,45.8z M28.2,39.8
			h-0.7V44h0.7c0.9,0,1.3-0.8,1.3-2.1C29.5,40.6,29.2,39.8,28.2,39.8z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M39.2,45.5l-1.7,0.4l-0.3-0.2l-1.7-3h-0.2v2.7l-0.3,0.3h-1.8L33,45.5v-7.2l0.3-0.3h3c1.9,0,2.9,0.7,2.9,2.2
			c0,1.3-0.9,1.8-1.6,2.1l1.8,2.8L39.2,45.5z M36.1,39.8h-0.8v1.3h0.5c0.5,0,1.1,0,1.1-0.7C36.9,39.9,36.8,39.8,36.1,39.8z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M43.6,45.9c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C47.5,44.4,46.2,45.9,43.6,45.9z
			 M45.3,36.8l-2.6,1l-0.3-0.1L42,36.9l0.1-0.3l2.4-1.4l0.3,0.1l0.6,1.2L45.3,36.8z M43.6,39.7c-1.1,0-1.4,1-1.4,2.2
			c0,1.2,0.3,2.2,1.4,2.2c1.1,0,1.4-1,1.4-2.2C45.1,40.8,44.7,39.7,43.6,39.7z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M53.5,45.8h-5.4l-0.3-0.3v-1.3l3.1-4.5h-2.6l-0.3-0.3v-1.2l0.3-0.3h5l0.3,0.3v1.3l-3.1,4.5h2.9l0.3,0.3v1.2
			L53.5,45.8z M51.9,37.7H50l-0.2-0.2v-1.6l0.2-0.2h1.9l0.2,0.2v1.6L51.9,37.7z"
                    />
                </g>
            </g>
        </svg>
    )
}

const MemoSuitcasePolish = React.memo(SuitcasePolish)
export default MemoSuitcasePolish
