import styled from "styled-components"

export const StyledTextArea = styled.textarea`
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    min-height: 104px;
    border: 1px solid ${(p) => p.theme.colors.white};

    &:active,
    &:focus {
        border: 1px solid ${(p) => p.theme.colors.grey};
    }

    &:disabled {
        background: ${(p) => p.theme.colors.grey};
        color: ${(p) => p.theme.colors.white};
    }
`

export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 24px;
`
export const ErrorMessage = styled.span`
    color: ${(p) => p.theme.colors.sangria600};
`
