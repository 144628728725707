import theme from "~styles/theme"

const section = {
    banana: {
        bg: theme.colors.banana,
        title: theme.colors.fig,
        bold: theme.colors.aloe,
        link: theme.colors.lapiz,
        paragraph: theme.colors.black,
        icon: theme.colors.cashew,
        button: "fig",
    },
    tiger: {
        bg: theme.colors.tiger,
        title: theme.colors.banana,
        bold: theme.colors.cashew,
        link: theme.colors.banana,
        paragraph: theme.colors.banana,
        icon: theme.colors.tiger600,
        button: "fig",
    },
    fig: {
        bg: theme.colors.fig,
        title: theme.colors.banana,
        bold: theme.colors.tiger,
        link: theme.colors.tiger,
        paragraph: theme.colors.banana,
        icon: theme.colors.fig600,
        button: "tiger",
    },
    lapiz: {
        bg: theme.colors.lapiz,
        title: theme.colors.banana,
        bold: theme.colors.fig,
        link: theme.colors.tiger,
        paragraph: theme.colors.banana,
        icon: theme.colors.cashew,
        button: "tiger",
    },
    aloe: {
        bg: theme.colors.aloe,
        title: theme.colors.cashew,
        bold: theme.colors.banana,
        link: theme.colors.tiger,
        paragraph: theme.colors.banana,
        icon: theme.colors.aloe600,
        button: "banana",
    },
}

export default section
