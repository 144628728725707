import { Spacer } from "./SpacerComponent.styled"

interface Props {
    blok: { spacer_size: string }
}

export function SpacerComponent({ blok }: Props): JSX.Element {
    const { spacer_size } = blok

    return <Spacer size={spacer_size} />
}
