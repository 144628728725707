import React, { ButtonHTMLAttributes, ReactNode } from "react"
import {
    Button as UIButton,
    css,
    Icon,
    SystemProps,
} from "@storyofams/react-ui"
import { motion } from "framer-motion"

import styled from "styled-components"
import { variant } from "styled-system"
import ButtonIcon from "~components/common/Icon/library/Button"
import { useCursor } from "../Cursor"
import { Link } from "../Link"

const variants = {
    cashew: {
        fontSize: [2.25, 3],
        path: {
            fill: "cashew",
        },

        "&:hover, &:focus": {
            opacity: 1,
            path: {
                fill: "cashew600",
            },
        },
    },
    sangria: {
        fontSize: [2.25, 3],
        path: {
            fill: "sangria",
        },

        "&:hover, &:focus": {
            opacity: 1,
            path: {
                fill: "sangria600",
            },
        },
    },
    banana: {
        fontSize: [2.25, 3],
        color: "black",
        path: {
            fill: "banana",
        },

        "&:hover, &:focus": {
            opacity: 1,
            path: {
                fill: "banana600",
            },
        },
    },
    tiger: {
        fontSize: [2.25, 3],
        path: {
            fill: "tiger",
        },

        "&:hover, &:focus": {
            opacity: 1,
            path: {
                fill: "tiger600",
            },
        },
    },
    fig: {
        fontSize: [2.25, 3],
        path: {
            fill: "fig",
        },

        "&:hover, &:focus": {
            opacity: 1,
            path: {
                fill: "fig600",
            },
        },
    },
    aloe: {
        fontSize: [2.25, 3],
        path: {
            fill: "aloe",
        },

        "&:hover, &:focus": {
            opacity: 1,
            path: {
                fill: "aloe600",
            },
        },
    },
}

const Wrapper = styled(motion.div)`
    position: relative;
    display: inline-flex;
`

type ButtonProps = SystemProps & {
    isLoading?: boolean
    to?: string | undefined
    children?: ReactNode
    variant?: any
} & Pick<ButtonHTMLAttributes<HTMLButtonElement>, "type">

const StyledButton = styled(UIButton)<ButtonProps>`
    background: none;
    position: relative;
    color: ${(p) => p.theme.colors.white};
    z-index: ${(p) => p.theme.zIndices.docked};

    .button-background {
        z-index: ${(p) => p.theme.zIndices.hide};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        svg {
            width: 100%;
            height: 100%;
        }

        path {
            transition: 0.18s fill ease-in-out;
        }
    }

    ${variant({ prop: "variant", scale: "buttons", variants: variants })}
`

export const Button = ({
    children,
    variant = "cashew",
    to,
    ...props
}: ButtonProps) => {
    const { zoom, reset } = useCursor()

    if (to) {
        return (
            <Link href={to} legacyBehavior>
                <StyledButton
                    variant={variant}
                    css={css({
                        width: ["100%", "auto"],
                        maxWidth: ["320px", "none"],
                        px: [2, 8],
                        py: 3,
                        lineHeight: "normal",
                        fontWeight: "bold",
                        position: "relative",
                        cursor: "pointer",
                    })}
                    {...props}
                    as="span"
                >
                    <Icon
                        as="span"
                        className="button-background"
                        icon={ButtonIcon}
                    />
                    {children}
                </StyledButton>
            </Link>
        )
    }

    return (
        <Wrapper
            onHoverStart={() => {
                zoom()
            }}
            onHoverEnd={() => {
                reset()
            }}
        >
            <StyledButton
                variant={variant}
                css={css({
                    width: ["100%", "auto"],
                    maxWidth: ["320px", "none"],
                    px: [2, 8],
                    py: 3,
                    lineHeight: "normal",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    cursor: "pointer",
                })}
                {...props}
            >
                <Icon
                    as="span"
                    className="button-background"
                    icon={<ButtonIcon />}
                />
                {children}
            </StyledButton>
        </Wrapper>
    )
}
