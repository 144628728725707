import { FC } from "react"
import { Box, Text, Stack, Flex, css } from "@storyofams/react-ui"
import { motion } from "framer-motion"
import { useRouter } from "next/router"
import { FormattedMessage } from "react-intl"
import { Link } from "~components/common/Link"

import { getNavLinkHref } from "~lib"

import { useCursor } from "../Cursor"

import { Image } from "../Image"
import { LanguageSwitch } from "../LanguageSwitch"
import { Shape } from "../Shape"
import { SocialLink } from "./SocialLink"
import Logo from "~components/common/Icon/library/Logo"
import Instagram from "~components/common/Icon/library/Instagram"
import Facebook from "../Icon/library/Facebook"
import Twitter from "../Icon/library/Twitter"
import Youtube from "../Icon/library/Youtube"

interface FooterProps {
    content: any
}

export const Links = ({ content }) => {
    const router = useRouter()
    const linkStyles = {
        fontSize: 2,
        textTransform: "uppercase",
        transition: "0.18s color ease-in-out",
        "&:hover": {
            color: "black",
        },
    }

    return (
        <>
            {content?.map(({ hyperlink, name }, i) => (
                <Link
                    href={getNavLinkHref(hyperlink)}
                    stylingProps={{
                        justifyContent: ["center", "flex-start"],
                        textAlign: ["center", "left"],
                    }}
                    key={`link-${i}`}
                >
                    <Text
                        as={"a" as any}
                        css={css({
                            color:
                                router?.asPath ===
                                getNavLinkHref(hyperlink).replace("/pl", "")
                                    ? "black"
                                    : "grey",
                            ...linkStyles,
                        })}
                    >
                        {name}
                    </Text>
                </Link>
            ))}
        </>
    )
}

export const Footer: FC<FooterProps> = ({ content }) => {
    const { background, reset } = useCursor()

    return (
        <>
            <Box bg={"cashew" as any}>
                <motion.div
                    onHoverStart={() => {
                        background()
                    }}
                    onHoverEnd={() => {
                        reset(true)
                    }}
                >
                    <Box
                        width="100%"
                        maxWidth="maxWidth"
                        px={[2, 2, 2, "132px", 20]}
                        margin={"0 auto" as any}
                        py={[8, 20]}
                    >
                        <Flex
                            flexDirection={["column", "row"]}
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Link href="/" stylingProps={{ cursor: "pointer" }}>
                                <a>
                                    <Shape
                                        width={["183px", "275px"]}
                                        height={["132px", "198px"]}
                                        icon={<Logo />}
                                    />
                                </a>
                            </Link>
                            <Stack
                                space={2}
                                flexDirection="column"
                                px={[0, 2]}
                                py={[4, 0]}
                            >
                                {content?.content?.footer_links && (
                                    <Links
                                        content={content.content.footer_links}
                                    />
                                )}
                            </Stack>
                            <Flex
                                flexDirection="column"
                                alignItems="flex-end"
                                minWidth={["0", "292px"]}
                            />
                        </Flex>
                        <Flex
                            flexDirection={["column-reverse", "row"]}
                            width="100%"
                            alignItems={["center", "flex-end"]}
                            justifyContent="space-between"
                        >
                            <Flex alignItems="flex-end" pt={4}>
                                <Text
                                    lineHeight="1.2"
                                    fontWeight="medium"
                                    mb={-0.5}
                                    mr={1}
                                    color="#6d6d6d"
                                    fontSize={2.25}
                                >
                                    <FormattedMessage
                                        id="components.common.Layout.Footer.greeting"
                                        defaultMessage="Initiative of"
                                    />
                                </Text>
                                <Image
                                    alt="Intersnack logo"
                                    src="/static/intersnack.png"
                                    width={120}
                                    height={65}
                                />
                            </Flex>
                            {(content?.content?.instagram_link ||
                                content?.content?.facebook_link ||
                                content?.content?.youtube_link ||
                                content?.content?.twitter_link) && (
                                <Stack space={3} fontSize={2} pt={[4, 0]}>
                                    {content?.content?.instagram_link && (
                                        <SocialLink
                                            href={
                                                content?.content?.instagram_link
                                            }
                                            icon={<Instagram />}
                                            ariaLabel="Instagram"
                                        />
                                    )}
                                    {content?.content?.facebook_link && (
                                        <SocialLink
                                            href={
                                                content?.content?.facebook_link
                                            }
                                            icon={<Facebook />}
                                            ariaLabel="Facebook"
                                        />
                                    )}
                                    {content?.content?.twitter_link && (
                                        <SocialLink
                                            href={
                                                content?.content?.twitter_link
                                            }
                                            icon={<Twitter />}
                                            ariaLabel="Twitter"
                                        />
                                    )}
                                    {content?.content?.youtube_link && (
                                        <SocialLink
                                            href={
                                                content?.content?.youtube_link
                                            }
                                            icon={<Youtube />}
                                            ariaLabel="Youtube"
                                        />
                                    )}
                                </Stack>
                            )}
                        </Flex>
                        <Flex
                            alignItems="center"
                            justifyContent={["center", "end"]}
                            width="100%"
                        >
                            <LanguageSwitch
                                storySlugs={content}
                                alignSelf={["flex-start", "flex-end"]}
                                mb={2}
                            />
                        </Flex>
                    </Box>
                </motion.div>
            </Box>
            <Box bg="banana">
                <Box
                    width="100%"
                    maxWidth="maxWidth"
                    px={[2, 2, 2, 2, 2]}
                    margin={"0 auto" as any}
                    py={[2, 2]}
                    display="flex"
                    gap={16}
                    justifyContent="flex-end"
                >
                    <Link
                        href="/[slug]"
                        as="/imprint"
                        stylingProps={{
                            css: css({
                                "&:hover": { color: ["black", "lapiz"] },
                            }),
                        }}
                    >
                        <a>Imprint</a>
                    </Link>
                    <Link
                        href="/privacy-statement"
                        stylingProps={{
                            css: css({
                                "&:hover": { color: ["black", "lapiz"] },
                            }),
                        }}
                    >
                        <a>
                            <FormattedMessage
                                id="components.common.Layout.Footer.privacyStatement"
                                defaultMessage="Privacy Statement"
                            />
                        </a>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
