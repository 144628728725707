import styled from "styled-components"
import { forwardRef } from "react"

export const StyledInput = styled.input<{ hasError?: boolean }>`
    /// reset input styles
    appearance: none;
    box-shadow: none;
    outline: none;
    border: 1px solid ${(p) => p.theme.colors.white};
    background: ${(p) => p.theme.colors.white};
    font-family: inherit;
    font-size: 16px;
    line-height: 100%;
    color: ${(p) => p.theme.colors.black};

    padding: 16px;
    border-radius: 16px;
    width: 100%;

    &:active,
    &:focus {
        border: 1px solid ${(p) => p.theme.colors.grey};
    }

    &:disabled {
        background: ${(p) => p.theme.colors.grey};
        color: ${(p) => p.theme.colors.white};
    }
`
export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 24px;
    position: relative;
`

export const InlineInput = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        gap: 24px;
    }
`

export const ErrorMessage = styled.div`
    color: ${(p) => p.theme.colors.sangria600};
`

export const StyledIcon = styled.div`
    position: absolute;
    right: 16px;
    top: 65%;
    transform: translateY(-50%);
    color: ${(p) => p.theme.colors.aloe600};
`
