import * as React from "react"

function Hamburger() {
    return (
        <svg
            width="35"
            height="9"
            viewBox="0 0 35 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter13)">
                <path
                    d="M34.0853 6.39323C35.3718 5.46876 35.3155 1.35496 33.8259 0.822001C30.9687 -0.200519 1.97256 -0.345105 0.777876 0.822013C-0.304885 1.87994 -0.365658 5.52204 1.28992 6.39323C2.96185 7.27272 33.1277 7.1286 34.0853 6.39323Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <filter
                    id="filter13"
                    x="0"
                    y="0"
                    width="35"
                    height="9"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

const MemoHamburger = React.memo(Hamburger)
export default MemoHamburger
