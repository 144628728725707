import * as React from "react"

export function Button() {
    return (
        <svg
            width="470"
            height="130"
            viewBox="0 0 470 130"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <path
                d="M462.817 107.619C478.491 89.1659 466.446 22.7703 446.547 11.8488C408.378 -9.10482 21.5394 -0.427365 5.57969 23.4894C-8.88486 45.1686 6.85647 104.543 28.9733 122.396C51.3085 140.418 450.024 122.688 462.817 107.619Z"
                fill="currentColor"
            />
        </svg>
    )
}

const MemoButton = React.memo(Button)
export default MemoButton
