import SbEditable from "storyblok-react"

import { Cards } from "./Cards"
import { DualImage } from "./DualImage"
import { FeaturedAsset } from "./FeaturedAsset"
import { FeaturedText } from "./FeaturedText"
import { Quote } from "./Quote"
import { Simple } from "./Simple"
import { FC } from "react"
import { SpacerComponent } from "~components/common/Blocks/spacerComponent"
import { useMedia } from "use-media"
import { useMediaQueryContext } from "~lib/useMediaQuery"
import ContactForm from "~components/common/Section/contactForm/ContactForm"

export interface SectionProps {
    content: any
    first: boolean
    sectionType: string
    extraPadding?: number | number[]
    extraMargin?: number | number[]
}

export const Section: FC<SectionProps> = ({
    content,
    first,
    sectionType,
    extraPadding,
    extraMargin,
}) => {
    let section = null
    const shared = { content, pb: extraPadding, first, mb: extraMargin }
    switch (sectionType) {
        case "featured video or image section":
            section = <FeaturedAsset {...shared} />
            break
        case "quote section":
            section = <Quote {...shared} />
            break
        case "dual image section":
            section = <DualImage {...shared} />
            break
        case "content section":
            section = <Simple {...shared} />
            break
        case "card section":
            section = <Cards {...shared} />
            break
        case "featured text section":
            section = <FeaturedText {...shared} />
            break
        case "SpacerComponent":
            section = <SpacerComponent blok={content} />
            break
        case "contactForm":
            section = <ContactForm {...shared} />
            break
        default:
            break
    }

    return (
        <SbEditable content={content}>
            <>{section}</>
        </SbEditable>
    )
}
