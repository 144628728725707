import * as React from "react"

function EyePolish() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 88.6 48.1"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <path
                        fill="#704CA5"
                        d="M78.3,11.5C73.9,8,68.8,5.1,63,3.1S50.9,0,44.3,0c-6.6,0-13,1.1-18.7,3.1S14.7,8,10.3,11.5
			C6,15.1,2.4,19.4,0,24.1c2.4,4.7,6,9,10.3,12.5c4.3,3.6,9.5,6.5,15.3,8.4c5.8,2,12.1,3.1,18.7,3.1c6.6,0,13-1.1,18.7-3.1
			c5.8-2,10.9-4.9,15.3-8.4c4.3-3.6,7.9-7.8,10.3-12.5C86.2,19.4,82.6,15.1,78.3,11.5z"
                    />
                </g>
                <circle fill="#7F58BE" cx="44.3" cy="24.1" r="24.1" />
            </g>
            <g>
                <path
                    fill="#F1D169"
                    d="M26.3,26.1H24l-0.3-0.3l-2-4.1l0.2,0.5v3.5l-0.3,0.3h-1.7l-0.3-0.3v-7.2l0.3-0.3h2.3l0.3,0.3l2.1,4.1l-0.2-0.5
		v-3.5l0.3-0.3h1.7l0.3,0.3v7.2L26.3,26.1z"
                />
                <path
                    fill="#F1D169"
                    d="M34.8,25.9L33,26.2L32.7,26l-0.5-1.8H30L29.4,26l-0.3,0.2L27.4,26l-0.2-0.3l2.7-7l0.3-0.3h1.8l0.3,0.3l2.6,7
		L34.8,25.9z M31.2,20.7l-0.6,1.7h1.1L31.2,20.7z"
                />
                <path
                    fill="#F1D169"
                    d="M38.1,26.2c-1.1,0-1.8-0.2-2.3-0.3l-0.3-0.3v-1.4l0.3-0.2c0.4,0.1,1.7,0.3,2.2,0.3c0.3,0,0.5-0.1,0.5-0.4
		c0-1-3-0.8-3-3.5c0-1.6,1.2-2.3,2.8-2.3c0.9,0,1.7,0.1,2.2,0.3l0.3,0.3v1.3l-0.3,0.3c-0.4-0.1-1.5-0.3-2-0.3
		c-0.4,0-0.5,0.1-0.5,0.4c0,0.9,3.1,0.7,3.1,3.5C41,25.6,39.7,26.2,38.1,26.2z"
                />
                <path
                    fill="#F1D169"
                    d="M47.2,26.1h-5.4l-0.3-0.3v-1.3l3.1-4.5h-2.6l-0.3-0.3v-1.2l0.3-0.3h5l0.3,0.3v1.3l-3.1,4.5h2.9l0.3,0.3v1.2
		L47.2,26.1z"
                />
                <path
                    fill="#F1D169"
                    d="M53.2,26.1h-4.5l-0.3-0.3v-7.2l0.3-0.3h4.4l0.3,0.3v1.3l-0.3,0.3h-2.3v1h2.1l0.3,0.3v1.3l-0.3,0.3h-2.1v1.4
		h2.4l0.3,0.3v1.3L53.2,26.1z"
                />
                <path
                    fill="#F1D169"
                    d="M60.5,25.9c-0.3,0.1-1.9,0.3-2.5,0.3c-2.7,0-3.8-1.2-3.8-4c0-2.6,1.2-4.1,3.9-4.1c0.9,0,1.8,0.3,2.2,0.4
		l0.3,0.3v1.3l-0.3,0.3C59.9,20.2,58.8,20,58,20c-1.3,0-1.4,0.9-1.4,2.2c0,1.4,0.3,2.2,1.4,2.2c0.1,0,0.5,0,0.6,0v-1h-0.5l-0.3-0.3
		v-1.3l0.3-0.3h2.4l0.3,0.3v3.8L60.5,25.9z"
                />
                <path
                    fill="#F1D169"
                    d="M65.6,26.2c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C69.5,24.7,68.2,26.2,65.6,26.2z
		 M65.6,20c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2s1.4-1,1.4-2.2C67,21.1,66.7,20,65.6,20z"
                />
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M20.4,36.5H18l-0.3-0.3l-2-4.1l0.2,0.5v3.5l-0.3,0.3h-1.7l-0.3-0.3V29l0.3-0.3h2.3l0.3,0.3l2.1,4.1l-0.2-0.5
		V29l0.3-0.3h1.7l0.3,0.3v7.2L20.4,36.5z"
                />
                <path
                    fill="#FFFFFF"
                    d="M26.9,36.5h-4.5l-0.3-0.3V29l0.3-0.3h4.4L27,29v1.3l-0.3,0.3h-2.3v1h2.1l0.3,0.3V33l-0.3,0.3h-2.1v1.4h2.4
		l0.3,0.3v1.3L26.9,36.5z"
                />
                <path
                    fill="#FFFFFF"
                    d="M34.4,36.2l-1.7,0.4l-0.3-0.2l-1.7-3h-0.2v2.7l-0.3,0.3h-1.8l-0.3-0.3V29l0.3-0.3h3c1.9,0,2.9,0.7,2.9,2.2
		c0,1.3-0.9,1.8-1.6,2.1l1.8,2.8L34.4,36.2z M31.4,30.5h-0.8v1.3h0.5c0.5,0,1.1,0,1.1-0.7C32.2,30.6,32,30.5,31.4,30.5z"
                />
                <path
                    fill="#FFFFFF"
                    d="M37.6,36.5h-1.8l-0.3-0.3V29l0.3-0.3h1.8l0.3,0.3v7.2L37.6,36.5z M42.3,36.2l-1.7,0.4l-0.3-0.2L38,32.6v-0.4
		l2.3-3.5l0.3-0.1l1.6,0.4l0.2,0.3l-2.2,3l2.3,3.6L42.3,36.2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M46.5,36.6c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C50.4,35.1,49.1,36.6,46.5,36.6z
		 M46.5,30.4c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2s1.4-1,1.4-2.2C47.9,31.5,47.6,30.4,46.5,30.4z"
                />
                <path
                    fill="#FFFFFF"
                    d="M59.5,36.2l-0.3,0.3h-2.1l-0.3-0.3l-0.7-4.3l-0.8,4.3l-0.3,0.3H53l-0.3-0.3l-2.1-7.1l0.2-0.3l2-0.2l0.3,0.2
		l1,5l0.7-4.8l0.3-0.3h2.1l0.3,0.3l0.8,4.8l1.1-5l0.3-0.2l1.8,0.2l0.2,0.3L59.5,36.2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M67.3,36.3c-0.3,0.1-1.2,0.3-1.9,0.3c-2.5,0-3.6-1.2-3.6-4c0-2.7,1.2-4.1,3.8-4.1c0.8,0,1.4,0.2,1.7,0.3
		l0.3,0.3v1.3l-0.3,0.3c-0.3-0.1-1.1-0.3-1.7-0.3c-1.2,0-1.4,0.9-1.4,2.2c0,1.4,0.2,2.2,1.3,2.2c0.5,0,1.5-0.2,1.8-0.3l0.3,0.2v1.4
		L67.3,36.3z"
                />
                <path
                    fill="#FFFFFF"
                    d="M75.6,36.3l-1.8,0.3l-0.3-0.2L73,34.6h-2.2l-0.6,1.8l-0.3,0.2l-1.6-0.3L68,36l2.7-7l0.3-0.3h1.8l0.3,0.3l2.6,7
		L75.6,36.3z M71.9,31.1l-0.6,1.7h1.1L71.9,31.1z"
                />
            </g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M27,13.4h-0.6v2.1l-0.3,0.3h-1.8l-0.3-0.3V8.4l0.3-0.3h3.1c1.9,0,2.9,1,2.9,2.6C30.3,12.4,29,13.4,27,13.4z
		 M27.2,9.8h-0.7v1.9h0.5c0.6,0,1.1-0.2,1.1-0.9C28,10.1,27.8,9.8,27.2,9.8z"
                />
                <path
                    fill="#FFFFFF"
                    d="M34.7,16c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C38.5,14.4,37.3,16,34.7,16z
		 M34.7,9.8c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2c1.1,0,1.4-1,1.4-2.2C36.1,10.8,35.8,9.8,34.7,9.8z"
                />
                <path
                    fill="#FFFFFF"
                    d="M44.6,15.8h-5.4L39,15.6v-1.3l3.1-4.5h-2.6l-0.3-0.3V8.4l0.3-0.3h5l0.3,0.3v1.3l-3.1,4.5h2.9l0.3,0.3v1.2
		L44.6,15.8z"
                />
                <path
                    fill="#FFFFFF"
                    d="M52.6,15.8h-2.4L50,15.6l-2-4.1l0.2,0.5v3.5l-0.3,0.3h-1.7l-0.3-0.3V8.4l0.3-0.3h2.3l0.3,0.3l2.1,4.1l-0.2-0.5
		V8.4l0.3-0.3h1.7l0.3,0.3v7.2L52.6,15.8z"
                />
                <path
                    fill="#FFFFFF"
                    d="M61.1,15.7L59.3,16L59,15.8l-0.5-1.8h-2.2l-0.6,1.8L55.4,16l-1.6-0.3l-0.2-0.3l2.7-7l0.3-0.3h1.8l0.3,0.3
		l2.6,7L61.1,15.7z M57.5,10.5l-0.6,1.7H58L57.5,10.5z"
                />
                <path
                    fill="#FFFFFF"
                    d="M62.9,18.3l-0.4,0.1l-1.2-0.7l-0.1-0.3c0.5-0.5,0.9-1.2,0.9-2.2V8.4l0.3-0.3h1.8l0.3,0.3v6.3
		C64.4,16.6,63.7,17.5,62.9,18.3z"
                />
            </g>
        </svg>
    )
}

const MemoEyePolish = React.memo(EyePolish)
export default MemoEyePolish
