import * as React from "react"

function Twitter() {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.671072 26.1221C2.14242 25.9425 3.63442 26.136 5.10577 25.8943C5.84484 25.7769 6.52181 25.5282 7.19185 25.2657C5.53408 24.202 4.04193 22.9862 2.65362 21.5287C2.15629 21.0106 2.51542 20.1403 3.24077 20.1196C3.83486 20.1472 4.41508 20.099 4.98144 19.9814C3.53767 18.1509 2.41867 16.1823 1.54834 13.9718C1.31354 13.3778 2.00422 12.7769 2.5638 12.9564C2.88842 13.0876 3.21998 13.1775 3.55846 13.2465C2.52929 10.7735 2.31513 8.27995 2.88842 5.54447C3.01969 4.91588 3.84857 4.76396 4.26995 5.17841C7.57179 8.42478 11.744 9.98595 16.2062 10.732C15.8124 7.67878 17.9816 4.43226 20.8274 3.36842C23.1898 2.49115 26.3604 3.09217 27.9492 5.0815C29.013 4.41146 30.4842 4.19037 31.6654 3.98315C32.2871 3.87946 32.8881 4.48742 32.522 5.10215C32.0937 5.82057 31.6862 6.58721 31.2373 7.3195C32.1491 7.05003 33.0886 6.88426 34.0279 6.76686C34.7049 6.67703 35.1124 7.72008 34.5944 8.13452C33.4961 9.01178 32.4046 10.0134 31.2373 10.8147C31.2028 18.5719 28.937 25.0789 22.0087 29.4928C16.2338 33.1817 5.49942 32.56 0.477716 27.5382H0.470783C0.249696 27.4829 0.132296 27.3171 0.0908512 27.1376C-0.102506 26.8475 0.0355418 26.4883 0.29114 26.3501C0.387894 26.2326 0.512227 26.1428 0.671072 26.1221Z"
                fill="currentColor"
            />
        </svg>
    )
}

const MemoTwitter = React.memo(Twitter)
export default MemoTwitter
