import styled from "styled-components"
import { ErrorMessage } from "~components/common/Input/Input.styled"

export const StyledForm = styled.form`
    max-width: 612px;
    width: 100%;
    margin: 0 auto;
`

export const InputWrapper = styled.div`
    max-width: 612px;
    width: 100%;
    margin: 0 auto;
`
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: left;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;
        margin-top: 40px;
    }
`

export const StyledErrorMessage = styled(ErrorMessage)`
    white-space: pre-line;
    padding: 16px 0;

    @media screen and (min-width: 768px) {
        padding: 0;
    }
`

export const SuccessMessage = styled.div`
    color: ${(p) => p.theme.colors.aloe600};
    white-space: pre-line;
    margin: 16px 0;

    @media screen and (min-width: 768px) {
        margin: 0;
    }
`
