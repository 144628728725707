import * as React from "react"

function Cashews() {
    return (
        <svg
            width="36"
            height="25"
            viewBox="0 0 36 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip111)">
                <path
                    d="M15.6598 20.3817C14.5297 16.8472 14.7565 13.7831 15.2943 11.5473C14.541 10.8614 13.8925 10.4042 13.5271 10.1474C11.9229 9.02109 9.92699 8.08968 6.92098 8.35205C5.72345 8.457 4.42097 8.81494 3.0454 9.57207C0.888342 10.7621 -0.747725 13.665 0.352355 15.927C0.794636 16.836 1.58362 17.5013 2.41009 17.6606C3.57014 17.8855 4.77517 17.5781 5.94084 17.5444C7.11963 17.5106 8.30592 17.6549 9.3479 18.2397C10.0469 18.6313 10.6635 19.1448 11.4319 19.9638C12.5338 21.1426 13.5796 22.387 14.8089 23.4383C15.5473 24.0699 16.46 24.3922 17.3146 24.3229C17.6613 24.2985 17.9874 24.2011 18.2741 24.0474C16.683 23.1122 15.8378 20.9402 15.6598 20.3817Z"
                    fill="currentColor"
                />
                <path
                    d="M19.5215 6.43189C18.4195 8.01174 15.987 12.3352 17.1414 18.381C17.1676 18.5159 17.7954 21.6906 19.84 22.5602C21.21 23.143 23.0803 22.0542 24.0099 20.1314C24.5927 18.9245 24.8213 17.5676 24.9225 16.2427C25.0125 15.062 25.2636 13.8551 25.972 12.8787C26.686 11.8948 27.7974 11.2539 28.9012 10.7966C30.9795 9.93454 33.755 9.87269 35.2168 7.92366C35.7565 7.20589 35.8765 6.34381 35.8709 5.4705C35.8615 4.07993 35.7772 2.51508 34.8851 1.51995C34.2573 0.820922 32.9698 -0.00929176 30.3967 7.86045e-05C26.2925 0.011323 22.5106 2.30894 19.5215 6.43189Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip111">
                    <rect width="36" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const MemoCashews = React.memo(Cashews)
export default MemoCashews
