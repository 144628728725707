import {
    DetailedHTMLProps,
    forwardRef,
    HTMLAttributes,
    ReactNode,
    TextareaHTMLAttributes,
} from "react"
import {
    ErrorMessage,
    StyledLabel,
    StyledTextArea,
} from "~components/common/TextArea/TextArea.styled"

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}
export function TextArea({
    label,
    error,
    ...rest
}: TextAreaProps & {
    label?: ReactNode
    error?: string | undefined
}) {
    return (
        <StyledLabel>
            {label}
            <StyledTextArea {...rest} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </StyledLabel>
    )
}

export default TextArea
