import { Text, Flex, Box, SystemProps, css, Stack } from "@storyofams/react-ui"
import SbEditable from "storyblok-react"

import section from "~styles/theme/section"

import { ContentImage, Title } from "~components/common/Blocks"
import { Paragraph } from "../Blocks"
import { Container } from "~components/common/Container"
import { Sticker } from "./Sticker"

type CardsProps = {
    first?: boolean
    content: {
        body?: any
        background_colour: "banana" | "tiger" | "fig" | "aloe" | "lapiz"
        title?: any
        text?: any
        sticker_type?:
            | "boundaries"
            | "cashews"
            | "check"
            | "factory"
            | "good"
            | "journey"
            | "nuts"
            | "promise"
        sticker_alignment?: "left" | "right"
        lapiz_bold_colour?: boolean
    }
} & SystemProps

export interface CardProps {
    content: any
    i: number
    background_colour: "banana" | "tiger" | "fig" | "aloe" | "lapiz"
}

const Card = ({ content, i }: CardProps) => {
    const { bg, bold, link, paragraph, title } =
        section[content?.background_colour] || section.banana
    let orderReverse = false

    if (i % 2 === 0) {
        orderReverse = true
    }

    return (
        <Flex width="100%" flexDirection={["column", "row"]}>
            {content?.image && (
                <ContentImage
                    height={[200, 430]}
                    content={content?.image}
                    flex={["0 1 100%", "0 1 56%"]}
                    order={[1, orderReverse ? 1 : 2]}
                />
            )}
            <Box
                borderRadius={["8px", "32px"]}
                px={[2, 6]}
                py={[3, 6]}
                bg={bg}
                flex={["0 1 100%", "0 1 44%"]}
                mt={[-4, 10]}
                ml={[2, orderReverse ? "-12%" : "12%"]}
                mr={[2, orderReverse ? 0 : "-12%"]}
                minHeight={[200, 430]}
                zIndex={1}
                textAlign="left"
                order={[1, orderReverse ? 2 : 1]}
            >
                {!!content?.pre_title && (
                    <Text
                        color={paragraph}
                        fontSize={[2, 2.5]}
                        lineHeight="high"
                        maxWidth="480px"
                        letterSpacing="0.1em"
                        css={css({ textTransform: "uppercase" })}
                    >
                        {content?.pre_title}
                    </Text>
                )}
                {!!content?.title?.content?.[0].content && (
                    <Title
                        text={content?.title}
                        boldColor={
                            !!content?.lapiz_bold_colour ? "lapiz" : title
                        }
                        titleColor={bold}
                        mb={[2, 2.5]}
                        fontSize={[4, 6]}
                    />
                )}
                {!!content?.text?.content?.[0].content && (
                    <Paragraph
                        text={content?.text}
                        boldColor={bold}
                        linkColor={link}
                        paragraphColor={paragraph}
                        fontSize={[2.25]}
                    />
                )}
            </Box>
        </Flex>
    )
}

export const Cards = ({ content, first, ...props }: CardsProps) => {
    const { bg, bold, title, link, paragraph } =
        section[content.background_colour] || section.banana

    return (
        <Container
            textAlign="center"
            bgColor={bg}
            childProps={{
                pt: first ? [148, 280] : [10, 20],
            }}
            {...props}
        >
            {!!content?.title?.content?.[0].content && (
                <Title
                    h1={first}
                    text={content?.title}
                    boldColor={!!content?.lapiz_bold_colour ? "lapiz" : bold}
                    titleColor={title}
                />
            )}
            {!!content?.text?.content?.[0].content && (
                <Paragraph
                    text={content?.text}
                    boldColor={bold}
                    linkColor={link}
                    paragraphColor={paragraph}
                />
            )}
            <Stack
                space={[3, 10]}
                position="relative"
                flexDirection="column"
                alignItems="center"
                width="100%"
            >
                {content?.body?.map((section, i) => (
                    <SbEditable content={section} key={`section-${i}`}>
                        <Card
                            background_colour={content?.background_colour}
                            content={section}
                            i={i}
                        />
                    </SbEditable>
                ))}
            </Stack>
            {content?.sticker_type && (
                <Sticker
                    align={content?.sticker_alignment}
                    type={content?.sticker_type}
                />
            )}
        </Container>
    )
}
