import * as React from "react"

function CheckMark() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.82 10.18L12 14.34L21.18 5.17999L24 7.99999L12 20L5 13L7.82 10.18Z"
                fill="#84AC4A"
            />
        </svg>
    )
}

const MemoCheckMark = React.memo(CheckMark)
export default MemoCheckMark
