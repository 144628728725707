import * as React from "react"

function FactoryPolish() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 65.4 76.4"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#81AA4A"
                    d="M0,1.4C0,0.6,0.6,0,1.4,0H17c0.8,0,1.4,0.6,1.4,1.4v21.8l14.7-9.1c0.9-0.6,2.1,0.1,2.1,1.2v8.5l12.8-9.4
		c0.9-0.7,2.2,0,2.2,1.1v8.2l12.8-9.4c0.9-0.7,2.2,0,2.2,1.1v8.2V75c0,0.8-0.6,1.4-1.4,1.4H1.4C0.6,76.4,0,75.8,0,75V32.3v-8.5V1.4z
		"
                />
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M6.9,18.4c0.1-0.3,0.5-0.3,0.6,0c0.3,0.7,0.8,1.8,1.4,2.4c0.6,0.6,1.7,1.1,2.4,1.4c0.3,0.1,0.3,0.5,0,0.6
			c-0.7,0.3-1.8,0.8-2.4,1.4c-0.6,0.6-1.1,1.7-1.4,2.4c-0.1,0.3-0.5,0.3-0.6,0c-0.3-0.7-0.8-1.8-1.4-2.4c-0.6-0.6-1.7-1.1-2.4-1.4
			c-0.2-0.1-0.2-0.5,0-0.6c0.7-0.3,1.8-0.8,2.4-1.4C6.1,20.2,6.6,19.1,6.9,18.4z"
                    />
                </g>
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M8.9,10.4c0.1-0.1,0.3-0.1,0.3,0c0.2,0.4,0.5,1,0.8,1.4c0.4,0.4,1,0.6,1.4,0.8c0.1,0.1,0.1,0.3,0,0.3
			c-0.4,0.2-1,0.5-1.4,0.8c-0.4,0.4-0.6,1-0.8,1.4c-0.1,0.1-0.3,0.1-0.3,0c-0.2-0.4-0.5-1-0.8-1.4c-0.3-0.3-1-0.6-1.4-0.8
			c-0.1-0.1-0.1-0.3,0-0.3c0.4-0.2,1-0.5,1.4-0.8C8.4,11.4,8.7,10.8,8.9,10.4z"
                    />
                </g>
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M34.6,70.2c0.1-0.2,0.5-0.2,0.6,0c0.2,0.4,0.5,0.9,0.8,1.2c0.3,0.3,0.8,0.6,1.2,0.8c0.2,0.1,0.2,0.5,0,0.6
			c-0.4,0.2-0.9,0.5-1.2,0.8c-0.3,0.3-0.6,0.8-0.8,1.2c-0.1,0.2-0.5,0.2-0.6,0c-0.2-0.4-0.5-0.9-0.8-1.2c-0.3-0.3-0.8-0.6-1.2-0.8
			c-0.2-0.1-0.2-0.5,0-0.6c0.4-0.2,0.9-0.5,1.2-0.8C34.2,71.1,34.4,70.6,34.6,70.2z"
                    />
                </g>
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M59.8,65.5c0.1-0.2,0.5-0.2,0.6,0c0.3,0.6,0.7,1.4,1.2,1.9c0.5,0.5,1.3,0.9,1.9,1.2c0.3,0.1,0.3,0.5,0,0.6
			c-0.6,0.3-1.4,0.7-1.9,1.2c-0.5,0.5-0.9,1.3-1.2,1.9c-0.1,0.2-0.5,0.3-0.6,0c-0.3-0.6-0.7-1.4-1.2-1.9c-0.5-0.5-1.3-0.9-1.9-1.2
			c-0.2-0.1-0.2-0.5,0-0.6c0.6-0.3,1.4-0.7,1.9-1.2C59.2,67,59.6,66.1,59.8,65.5z"
                    />
                </g>
                <circle fill="#708F3F" cx="7.2" cy="71.3" r="2.4" />
                <circle fill="#708F3F" cx="6.4" cy="62.8" r="1.3" />
                <circle fill="#708F3F" cx="3.6" cy="67.2" r="1.6" />
                <circle fill="#708F3F" cx="60.3" cy="33.4" r="2.4" />
                <circle fill="#708F3F" cx="59.2" cy="28.4" r="1.3" />
                <circle fill="#708F3F" cx="61.6" cy="23.3" r="1.3" />
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M20.1,35.4c-0.2,0.1-1.1,0.3-1.7,0.3c-2.2,0-3.2-1.1-3.2-3.5c0-2.3,1-3.6,3.3-3.6c0.7,0,1.2,0.1,1.5,0.3
			l0.2,0.2v1.1L20,30.4c-0.3-0.1-1-0.2-1.5-0.2c-1,0-1.2,0.8-1.2,1.9c0,1.2,0.2,1.9,1.2,1.9c0.5,0,1.3-0.2,1.5-0.3l0.3,0.2v1.2
			L20.1,35.4z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M25.7,35.5H21l-0.2-0.2v-1.1l2.7-3.9h-2.2L21.1,30v-1l0.2-0.2h4.4l0.2,0.2v1.1L23.2,34h2.5l0.2,0.2v1
			L25.7,35.5z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M30.6,33.6v1.7l-0.2,0.2h-1.6l-0.2-0.2v-1.7l-2.3-4.4l0.2-0.3l1.6-0.3l0.3,0.2l1.3,3l1.4-3.1l0.3-0.1l1.4,0.3
			l0.2,0.3L30.6,33.6z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M35.7,35.6c-0.9,0-1.5-0.2-2-0.3l-0.2-0.2v-1.2l0.2-0.2c0.4,0.1,1.5,0.3,1.9,0.3c0.3,0,0.4-0.1,0.4-0.3
			c0-0.8-2.7-0.7-2.7-3.1c0-1.4,1-2,2.4-2c0.8,0,1.5,0.1,1.9,0.3l0.2,0.2v1.2l-0.2,0.2c-0.4-0.1-1.3-0.3-1.8-0.3
			c-0.3,0-0.4,0.1-0.4,0.3c0,0.8,2.7,0.6,2.7,3.1C38.2,35.1,37.1,35.6,35.7,35.6z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M43.6,30.3h-1.6v5l-0.2,0.2h-1.6L40,35.3v-5h-1.5l-0.2-0.2V29l0.2-0.2h5.1l0.2,0.2v1.1L43.6,30.3z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M49.9,35.4l-1.5,0.3l-0.3-0.2l-0.5-1.6h-1.9l-0.5,1.6l-0.3,0.2l-1.4-0.2l-0.1-0.3l2.4-6.1l0.2-0.2h1.6
			l0.2,0.2l2.3,6.1L49.9,35.4z M46.7,30.8l-0.5,1.5h0.9L46.7,30.8z"
                    />
                </g>
                <g>
                    <path
                        fill="#EBD078"
                        d="M7.6,40.1H5.7v1h1.7l0.2,0.2v1.1l-0.2,0.2H5.7v2.4l-0.2,0.2H3.9l-0.2-0.2v-6.3l0.2-0.2h3.7l0.2,0.2v1.1
			L7.6,40.1z"
                    />
                    <path
                        fill="#EBD078"
                        d="M14.1,45.2l-1.5,0.3l-0.3-0.2l-0.5-1.6H9.9l-0.5,1.6l-0.3,0.2l-1.4-0.2L7.5,45l2.4-6.1l0.2-0.2h1.6l0.2,0.2
			l2.3,6.1L14.1,45.2z M10.9,40.6l-0.5,1.5h0.9L10.9,40.6z"
                    />
                    <path
                        fill="#EBD078"
                        d="M17.5,45.3h-2.4l-0.2-0.2v-6.3l0.2-0.2h2.4c1.6,0,2.5,0.5,2.5,1.8c0,0.6-0.4,1.2-0.9,1.4
			c0.5,0.1,1.1,0.8,1.1,1.6C20.2,44.8,19,45.3,17.5,45.3z M17.4,40.1h-0.5v1h0.5c0.4,0,0.6-0.1,0.6-0.5C18,40.1,17.9,40.1,17.4,40.1
			z M17.5,42.6h-0.6v1.2h0.6c0.4,0,0.7-0.2,0.7-0.6C18.2,42.8,18,42.6,17.5,42.6z"
                    />
                    <path
                        fill="#EBD078"
                        d="M26.5,45.1L25,45.5l-0.3-0.1l-1.5-2.6h-0.1v2.4l-0.2,0.2h-1.6l-0.2-0.2v-6.3l0.2-0.2H24c1.7,0,2.5,0.6,2.5,2
			c0,1.1-0.8,1.6-1.4,1.8l1.6,2.5L26.5,45.1z M23.8,40.1h-0.7v1.1h0.4c0.5,0,1,0,1-0.6C24.5,40.2,24.4,40.1,23.8,40.1z"
                    />
                    <path
                        fill="#EBD078"
                        d="M30.9,43.4v1.7l-0.2,0.2h-1.6l-0.2-0.2v-1.7L26.6,39l0.2-0.3l1.6-0.3l0.3,0.2l1.3,3l1.4-3.1l0.3-0.1l1.4,0.3
			l0.2,0.3L30.9,43.4z"
                    />
                    <path
                        fill="#EBD078"
                        d="M35.6,45.3h-1.6l-0.2-0.2v-6.3l0.2-0.2h1.6l0.2,0.2v6.3L35.6,45.3z M39.8,45.1l-1.5,0.3L38,45.3l-2-3.4v-0.3
			l2-3.1l0.3-0.1l1.4,0.4l0.2,0.3l-1.9,2.6l2,3.1L39.8,45.1z"
                    />
                    <path
                        fill="#EBD078"
                        d="M46.8,45.2l-1.5,0.3L45,45.3l-0.5-1.6h-1.9l-0.5,1.6l-0.3,0.2l-1.4-0.2L40.3,45l2.4-6.1l0.2-0.2h1.6l0.2,0.2
			l2.3,6.1L46.8,45.2z M43.7,40.6l-0.5,1.5h0.9L43.7,40.6z"
                    />
                    <path
                        fill="#EBD078"
                        d="M54.5,40.1h-1.6v5l-0.2,0.2h-1.6l-0.2-0.2v-5h-1.5l-0.2-0.2v-1.1l0.2-0.2h5.1l0.2,0.2v1.1L54.5,40.1z"
                    />
                    <path
                        fill="#EBD078"
                        d="M58.2,45.5c-2.2,0-3.4-1.3-3.4-3.5c0-2.1,1-3.6,3.4-3.6c2.3,0,3.4,1.4,3.4,3.5C61.6,44.1,60.5,45.5,58.2,45.5
			z M58.3,40c-1,0-1.3,0.9-1.3,1.9c0,1,0.3,1.9,1.2,1.9s1.3-0.9,1.3-1.9C59.5,40.9,59.2,40,58.3,40z"
                    />
                </g>
                <g>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M7,55.2H4.6l-0.2-0.2v-6.3l0.2-0.2H7c1.6,0,2.5,0.5,2.5,1.8c0,0.6-0.4,1.2-0.9,1.4c0.5,0.1,1.1,0.8,1.1,1.6
				C9.7,54.7,8.5,55.2,7,55.2z M6.9,49.9H6.4v1h0.5c0.4,0,0.6-0.1,0.6-0.5C7.5,50,7.4,49.9,6.9,49.9z M7,52.5H6.4v1.2H7
				c0.4,0,0.7-0.2,0.7-0.6C7.7,52.6,7.5,52.5,7,52.5z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M14.8,55.2h-3.9l-0.2-0.2v-6.3l0.2-0.2h3.8l0.2,0.2v1.1L14.7,50h-2v0.9h1.9l0.2,0.2v1.1l-0.2,0.2h-1.9v1.2
				h2.1l0.2,0.2v1.1L14.8,55.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M20.4,55.2h-4.7l-0.2-0.2v-1.1l2.7-3.9H16l-0.2-0.2v-1l0.2-0.2h4.4l0.2,0.2v1.1l-2.7,3.9h2.5l0.2,0.2v1
				L20.4,55.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M24.1,53.1h-0.5v1.9l-0.2,0.2h-1.6l-0.2-0.2v-6.3l0.2-0.2h2.7c1.7,0,2.5,0.9,2.5,2.2
				C27,52.2,25.9,53.1,24.1,53.1z M24.2,49.9h-0.6v1.6H24c0.5,0,1-0.2,1-0.8C25,50.2,24.8,49.9,24.2,49.9z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M29.6,55.2h-1.6l-0.2-0.2v-6.3l0.2-0.2h1.6l0.2,0.2v6.3L29.6,55.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M35.3,55.2h-3.9l-0.2-0.2v-6.3l0.2-0.2h3.8l0.2,0.2v1.1L35.3,50h-2v0.9h1.9l0.2,0.2v1.1l-0.2,0.2h-1.9v1.2
				h2.1l0.2,0.2v1.1L35.3,55.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M41.1,55c-0.2,0.1-1.1,0.3-1.7,0.3c-2.2,0-3.2-1.1-3.2-3.5c0-2.3,1-3.6,3.3-3.6c0.7,0,1.2,0.1,1.5,0.2
				l0.2,0.2v1.1L41,50.1c-0.3-0.1-1-0.2-1.5-0.2c-1,0-1.2,0.8-1.2,1.9c0,1.2,0.2,1.9,1.2,1.9c0.5,0,1.3-0.2,1.5-0.3l0.3,0.2v1.2
				L41.1,55z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M46.7,55.2H42l-0.2-0.2v-1.1l2.7-3.9h-2.2L42,49.7v-1l0.2-0.2h4.4l0.2,0.2v1.1l-2.7,3.9h2.5l0.2,0.2v1
				L46.7,55.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M53.7,55.2h-2.1l-0.2-0.2l-1.8-3.6l0.2,0.5v3.1l-0.2,0.2H48l-0.2-0.2v-6.3l0.2-0.2h2l0.2,0.2l1.8,3.6
				l-0.2-0.5v-3.1l0.2-0.2h1.5l0.2,0.2v6.3L53.7,55.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M61.1,55.1l-1.5,0.3l-0.3-0.2l-0.5-1.6H57l-0.5,1.6l-0.3,0.2l-1.4-0.2l-0.1-0.3l2.4-6.1l0.2-0.2h1.6l0.2,0.2
				l2.3,6.1L61.1,55.1z M58,50.5L57.5,52h0.9L58,50.5z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            fill="#EBD078"
                            d="M15.1,59.8h-1.9v1H15l0.2,0.2v1.1L15,62.4h-1.7v2.4L13,65h-1.6l-0.2-0.2v-6.3l0.2-0.2h3.7l0.2,0.2v1.1
				L15.1,59.8z"
                        />
                        <path
                            fill="#EBD078"
                            d="M21.6,64.9l-1.5,0.3L19.8,65l-0.5-1.6h-1.9L16.9,65l-0.3,0.2l-1.4-0.2L15,64.6l2.4-6.1l0.2-0.2h1.6l0.2,0.2
				l2.3,6.1L21.6,64.9z M18.5,60.3L18,61.8h0.9L18.5,60.3z"
                        />
                        <path
                            fill="#EBD078"
                            d="M25,65h-2.4l-0.2-0.2v-6.3l0.2-0.2H25c1.6,0,2.5,0.5,2.5,1.8c0,0.6-0.4,1.2-0.9,1.4c0.5,0.1,1.1,0.8,1.1,1.6
				C27.7,64.5,26.5,65,25,65z M24.9,59.8h-0.5v1h0.5c0.4,0,0.6-0.1,0.6-0.5C25.5,59.8,25.4,59.8,24.9,59.8z M25,62.3h-0.6v1.2H25
				c0.4,0,0.7-0.2,0.7-0.6C25.7,62.5,25.5,62.3,25,62.3z"
                        />
                        <path
                            fill="#EBD078"
                            d="M34.1,64.8l-1.5,0.4L32.3,65l-1.5-2.6h-0.1v2.4L30.4,65h-1.6l-0.2-0.2v-6.3l0.2-0.2h2.6c1.7,0,2.5,0.6,2.5,2
				c0,1.1-0.8,1.6-1.4,1.8l1.6,2.5L34.1,64.8z M31.4,59.8h-0.7v1.1h0.4c0.5,0,1,0,1-0.6C32,59.9,31.9,59.8,31.4,59.8z"
                        />
                        <path
                            fill="#EBD078"
                            d="M38.4,63.1v1.7L38.2,65h-1.6l-0.2-0.2v-1.7l-2.3-4.4l0.2-0.3l1.6-0.3l0.3,0.2l1.3,3l1.4-3.1l0.3-0.1l1.4,0.3
				l0.2,0.3L38.4,63.1z"
                        />
                        <path
                            fill="#EBD078"
                            d="M43.2,65h-1.6l-0.2-0.2v-6.3l0.2-0.2h1.6l0.2,0.2v6.3L43.2,65z M47.3,64.8l-1.5,0.3L45.5,65l-2-3.4v-0.3
				l2-3.1l0.3-0.1l1.4,0.4l0.2,0.3l-1.9,2.6l2,3.1L47.3,64.8z"
                        />
                        <path
                            fill="#EBD078"
                            d="M54.4,64.9l-1.5,0.3L52.5,65l-0.5-1.6h-1.9L49.6,65l-0.3,0.2l-1.4-0.2l-0.1-0.3l2.4-6.1l0.2-0.2H52l0.2,0.2
				l2.3,6.1L54.4,64.9z M51.2,60.3l-0.5,1.5h0.9L51.2,60.3z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

const MemoFactoryPolish = React.memo(FactoryPolish)
export default MemoFactoryPolish
