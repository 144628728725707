import * as React from "react"

function Flag() {
    return (
        <svg
            width="21"
            height="27"
            viewBox="0 0 21 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1298 7.79422C17.6836 7.33397 19.328 5.08236 20.3905 3.59243C20.8264 2.98115 20.4519 2.14029 19.706 2.05466C16.828 1.72427 11.2435 1.09136 11.2435 1.09136C11.2435 1.09136 6.95953 1.03835 4.15379 1.67279V0.913449C4.15379 0.408965 3.74483 0 3.24035 0V0C2.73586 0 2.3269 0.408965 2.3269 0.913448V23.2761C1.54825 23.5663 0.663371 24.4844 0.244537 25.4488C0.0114926 25.9854 0.500026 26.4583 1.08305 26.4097L5.08305 26.0764C5.60134 26.0332 6.0178 25.5897 5.85952 25.0943C5.58875 24.2468 4.95036 23.573 4.15379 23.2761C4.15379 23.2761 4.5 21.5 4.5 19.5C4.5 17.5 4.15379 15.2254 4.15379 15.2254C6.83985 14.6531 9.31156 15.4477 11.5 15.4497C14.7776 15.4526 17.4198 15.5548 19.1951 15.663C20.0699 15.7164 20.6809 14.6768 20.196 13.9467C18.7132 11.7141 16.5658 8.26287 17.1298 7.79422Z"
                fill="currentColor"
            />
        </svg>
    )
}

const MemoFlag = React.memo(Flag)
export default MemoFlag
