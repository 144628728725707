import React from "react"
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button"
import { Box, Icon, Flex, SystemProps, ChevronDown } from "@storyofams/react-ui"
import Link from "next/link"
import { useRouter } from "next/router"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import "@reach/menu-button/styles.css"

import { getLocales } from "~lib"

const LANGUAGES = {
    en: { label: "English" },
    pl: { label: "Polski" },
}

const StyledMenuButton = styled.button`
    && {
        appearance: none;
        border: none;
        background-color: ${(p) => p.theme.colors.cashew600};
        border-radius: 4px;
        color: black;
        font-size: ${(p) => p.theme.fontSizes[1.75]};
        line-height: 100%;
        user-select: none;
        cursor: pointer;
        transition: color 0.2s ease-out, background-color 0.2s ease-in-out;
        min-width: 120px;

        &:hover {
            background-color: ${(p) => p.theme.colors.grey};
        }
    }

    &[aria-expanded="true"] .chevron-down {
        transform: rotate(180deg);
    }
    @media (max-width: ${(p) => p.theme.breakpoints.sm}) {
        width: 100%;
    }
`

const StyledMenuList = styled.div`
    margin-bottom: 8px;
    && {
        padding: 7px 4px;
        background: ${(p) => p.theme.colors.cashew600};
        border: none;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px 8px 8px 0px;
        margin: 8px 0;
    }
`

const StyledItem = styled.span`
    && {
        padding: 9px;
        font-size: 14px;
        line-height: 100%;
        color: ${(p) => p.theme.colors.black};
        text-decoration: none;
        border-radius: 4px;
        min-width: 180px;
        transition: background-color 0.2s ease-in-out;

        &[data-selected],
        &:hover {
            background-color: ${(p) => p.theme.colors.grey};
        }
    }
`

interface LanguageSwitchProps extends SystemProps {
    storySlugs?: any
}

export const LanguageSwitch = ({
    storySlugs,
    ...props
}: LanguageSwitchProps) => {
    const { pathname, defaultLocale, locale: routerLocale, query } = useRouter()

    const locale = (routerLocale || process.env.DEFAULT_LOCALE)?.toUpperCase()

    const getLocalizedPathname = (value) => {
        if (value === defaultLocale) {
            if (storySlugs?.default_full_slug) {
                return `/${storySlugs.default_full_slug}`
            }
        } else if (storySlugs?.translated_slugs?.length) {
            const path = storySlugs.translated_slugs.find(
                ({ lang }) => lang === value
            )?.path

            if (path) {
                return `/${path}`
            }
        }

        return { pathname, query }
    }

    if (getLocales()?.length <= 1) {
        return null
    }

    return (
        <Flex
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt={[3, 0]}
            {...props}
        >
            <Box color="black" lineHeight="140%" fontSize={1.75} mr={2}>
                <FormattedMessage
                    id="components.common.LanguageSwitch.index.language"
                    defaultMessage="Language:"
                />
            </Box>
            <Menu>
                <MenuButton as={StyledMenuButton}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        p={1.5}
                        justifyContent="space-between"
                    >
                        <Box color="black" fontSize={1.75} lineHeight="100%">
                            {LANGUAGES?.[locale?.toLowerCase()]?.label}
                        </Box>

                        <Icon
                            ml={2}
                            icon={<ChevronDown />}
                            fontSize={1.5}
                            className="chevron-down"
                        />
                    </Box>
                </MenuButton>
                <MenuList as={StyledMenuList}>
                    {getLocales().map((value) => (
                        <Link
                            key={value}
                            href={getLocalizedPathname(value)}
                            passHref
                            locale={value}
                        >
                            <MenuItem
                                key={value}
                                as={StyledItem}
                                onSelect={() => {}}
                            >
                                {LANGUAGES?.[value]?.label}
                            </MenuItem>
                        </Link>
                    ))}
                </MenuList>
            </Menu>
        </Flex>
    )
}
