import { css, Flex, SystemProps, Text } from "@storyofams/react-ui"

import { getLinkHref } from "~lib"
import section from "~styles/theme/section"
import { Title, Paragraph } from "../Blocks"
import { Button } from "../Button"
import { Container } from "~components/common/Container"
import { Sticker } from "./Sticker"

type FeaturedTextProps = {
    first?: boolean
    content: {
        background_colour: "banana" | "tiger" | "fig" | "aloe" | "lapiz"
        title: any
        pre_title?: any
        text?: any
        sticker_type?:
            | "boundaries"
            | "cashews"
            | "check"
            | "factory"
            | "good"
            | "journey"
            | "nuts"
            | "promise"
        sticker_alignment?: "left" | "right"
        button_text?: string
        button_link?: {
            cached_url?: string
            fieldtype?: string
            id?: string
            linktype?: string
            url?: string
            email?: string
        }
        lapiz_bold_colour?: boolean
    }
} & SystemProps

export const FeaturedText = ({
    content,
    first,
    ...props
}: FeaturedTextProps) => {
    const { bg, button, bold, link, paragraph, title } =
        section[content.background_colour] || section.banana

    return (
        <Container
            childProps={{
                pt: first ? [148, 280] : [10, 20],
            }}
            bgColor={bg}
            textAlign="center"
            {...props}
        >
            <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                {content?.pre_title && (
                    <Text
                        color={paragraph}
                        fontSize={[2, 2.5]}
                        lineHeight="high"
                        maxWidth="480px"
                        letterSpacing="0.1em"
                        mb={[1, 2]}
                        css={css({ textTransform: "uppercase" })}
                    >
                        {content?.pre_title}
                    </Text>
                )}
                {!!content?.title?.content?.[0].content && (
                    <Title
                        text={content?.title}
                        boldColor={
                            !!content?.lapiz_bold_colour ? "lapiz" : bold
                        }
                        titleColor={title}
                        fontSize={[5, 8]}
                        h1={first}
                    />
                )}
            </Flex>
            {!!content?.text?.content?.[0].content && (
                <Paragraph
                    text={content?.text}
                    boldColor={bold}
                    linkColor={link}
                    paragraphColor={paragraph}
                />
            )}
            {content?.button_text && content?.button_link && (
                <Button variant={button as any} to={getLinkHref(content)}>
                    {content?.button_text}
                </Button>
            )}
            {content?.sticker_type && (
                <Sticker
                    align={content?.sticker_alignment}
                    type={content?.sticker_type}
                />
            )}
        </Container>
    )
}
