import * as React from "react"

function SmileyPolish() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 69.4 69.4"
            xmlSpace="preserve"
        >
            <g>
                <circle fill="#EBD078" cx="34.7" cy="34.7" r="34.7" />
                <path
                    stroke="#34393F"
                    strokeWidth="1.841"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    d="M44.8,69.4"
                />
                <path
                    stroke="#34393F"
                    strokeWidth="1.841"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    d="M41.8,59.1"
                />
                <g>
                    <path
                        fill="#DC783C"
                        d="M11.6,28.4H9.3L9,28.1l-2-4.1l0.2,0.5v3.5l-0.3,0.3H5.2l-0.3-0.3v-7.2l0.3-0.3h2.3l0.3,0.3L9.8,25l-0.2-0.5
			v-3.5l0.3-0.3h1.7l0.3,0.3v7.2L11.6,28.4z"
                    />
                    <path
                        fill="#DC783C"
                        d="M18.1,28.4h-4.5l-0.3-0.3v-7.2l0.3-0.3h4.4l0.3,0.3v1.3l-0.3,0.3h-2.3v1h2.1l0.3,0.3V25l-0.3,0.3h-2.1v1.4
			h2.4l0.3,0.3v1.3L18.1,28.4z"
                    />
                    <path
                        fill="#DC783C"
                        d="M25.7,28.2L24,28.6l-0.3-0.2l-1.7-3h-0.2v2.7l-0.3,0.3h-1.8l-0.3-0.3v-7.2l0.3-0.3h3c1.9,0,2.9,0.7,2.9,2.2
			c0,1.3-0.9,1.8-1.6,2.1l1.8,2.8L25.7,28.2z M22.7,22.4h-0.8v1.3h0.5c0.5,0,1.1,0,1.1-0.7C23.4,22.5,23.3,22.4,22.7,22.4z"
                    />
                    <path
                        fill="#DC783C"
                        d="M28.9,28.4h-1.8l-0.3-0.3v-7.2l0.3-0.3h1.8l0.3,0.3v7.2L28.9,28.4z M33.6,28.2l-1.7,0.4l-0.3-0.2l-2.3-3.9
			v-0.4l2.3-3.5l0.3-0.1l1.6,0.4l0.2,0.3l-2.2,3l2.3,3.6L33.6,28.2z"
                    />
                    <path
                        fill="#DC783C"
                        d="M37.8,28.6c-2.5,0-3.8-1.5-3.8-4c0-2.4,1.2-4.1,3.9-4.1c2.6,0,3.8,1.6,3.8,4C41.6,27,40.4,28.6,37.8,28.6z
			 M37.8,22.3c-1.1,0-1.4,1-1.4,2.2c0,1.2,0.3,2.2,1.4,2.2c1.1,0,1.4-1,1.4-2.2C39.2,23.4,38.9,22.3,37.8,22.3z"
                    />
                    <path
                        fill="#DC783C"
                        d="M50.8,28.1l-0.3,0.3h-2.1l-0.3-0.3l-0.7-4.3l-0.8,4.3l-0.3,0.3h-2.1L44,28.1L42,21l0.2-0.3l2-0.2l0.3,0.2l1,5
			l0.7-4.8l0.3-0.3h2.1l0.3,0.3l0.8,4.8l1.1-5l0.3-0.2l1.8,0.2l0.2,0.3L50.8,28.1z"
                    />
                    <path
                        fill="#DC783C"
                        d="M58.6,28.2c-0.3,0.1-1.2,0.3-1.9,0.3c-2.5,0-3.6-1.2-3.6-4c0-2.7,1.2-4.1,3.8-4.1c0.8,0,1.4,0.2,1.7,0.3
			l0.3,0.3v1.3l-0.3,0.3c-0.3-0.1-1.1-0.3-1.7-0.3c-1.2,0-1.4,0.9-1.4,2.2c0,1.4,0.2,2.2,1.3,2.2c0.5,0,1.5-0.2,1.8-0.3l0.3,0.2V28
			L58.6,28.2z"
                    />
                    <path
                        fill="#DC783C"
                        d="M64.6,28.4h-4.5l-0.3-0.3v-7.2l0.3-0.3h4.4l0.3,0.3v1.3l-0.3,0.3h-2.3v1h2.1l0.3,0.3V25l-0.3,0.3h-2.1v1.4
			h2.4l0.3,0.3v1.3L64.6,28.4z"
                    />
                </g>
                <g>
                    <path
                        fill="#704CA5"
                        d="M20.1,38.2l-1.7,0.4l-0.3-0.2l-1.7-3h-0.2v2.7L16,38.4h-1.8l-0.3-0.3V31l0.3-0.3h3c1.9,0,2.9,0.7,2.9,2.2
			c0,1.3-0.9,1.8-1.6,2.1l1.8,2.8L20.1,38.2z M17.1,32.4h-0.8v1.3h0.5c0.5,0,1.1,0,1.1-0.7C17.8,32.5,17.7,32.4,17.1,32.4z"
                    />
                    <path
                        fill="#704CA5"
                        d="M26.4,38.4H21l-0.3-0.3v-1.3l3.1-4.5h-2.6l-0.3-0.3V31l0.3-0.3h5l0.3,0.3v1.3l-3.1,4.5h2.9l0.3,0.3v1.2
			L26.4,38.4z"
                    />
                    <path
                        fill="#704CA5"
                        d="M34.5,38.3l-0.2,0.3c-0.5,0.2-1.3,0.6-1.3,1c0,0.2,0.1,0.3,0.3,0.3c0.3,0,0.8-0.3,1-0.4l0.3,0.2v0.8l-0.1,0.3
			c-0.3,0.2-0.9,0.5-1.5,0.5c-1,0-1.5-0.4-1.5-1.4c0-0.5,0.4-0.9,0.9-1.2l-0.5-1.9h-2.2l-0.6,1.8l-0.3,0.2l-1.6-0.3L27,38l2.7-7
			l0.3-0.3h1.8l0.3,0.3l2.6,7L34.5,38.3z M30.9,33l-0.6,1.7h1.1L30.9,33z"
                    />
                    <path
                        fill="#704CA5"
                        d="M38.3,38.4h-2.7l-0.3-0.3V31l0.3-0.3h2.8c2.6,0,3.7,1.6,3.7,3.7C42.1,36.7,41,38.4,38.3,38.4z M38.4,32.5
			h-0.7v4.2h0.7c0.9,0,1.3-0.8,1.3-2.1C39.7,33.2,39.3,32.5,38.4,32.5z"
                    />
                    <path
                        fill="#704CA5"
                        d="M48,38.4h-5.4l-0.3-0.3v-1.3l3.1-4.5H43l-0.3-0.3V31l0.3-0.3h5l0.3,0.3v1.3l-3.1,4.5H48l0.3,0.3v1.2L48,38.4z
			"
                    />
                    <path
                        fill="#704CA5"
                        d="M56.2,38.3L56,38.5c-0.5,0.2-1.3,0.6-1.3,1c0,0.2,0.1,0.3,0.3,0.3c0.3,0,0.8-0.3,1-0.4l0.3,0.2v0.8l-0.1,0.3
			c-0.3,0.2-0.9,0.5-1.5,0.5c-1,0-1.5-0.4-1.5-1.4c0-0.5,0.4-0.9,0.9-1.2l-0.5-1.9h-2.2l-0.6,1.8l-0.3,0.2l-1.6-0.3L48.7,38l2.7-7
			l0.3-0.3h1.8l0.3,0.3l2.6,7L56.2,38.3z M52.6,33L52,34.8h1.1L52.6,33z"
                    />
                </g>
                <g>
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#DC783C"
                        d="M48.3,52.5l-6.4,3l2.9,4.4l0,0l0,0c1.7,2.5,5.7,2.8,9,0.7c3.3-2.2,4.6-6,2.9-8.6l0,0l0,0c0,0,0,0,0,0
			c-0.1-0.1-0.1-0.2-0.2-0.3l-3.4-4.3L48.3,52.5z"
                    />
                    <line
                        fill="none"
                        stroke="#D06523"
                        strokeWidth="1.841"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        x1="48.6"
                        y1="53"
                        x2="52.5"
                        y2="58.4"
                    />
                </g>
                <path
                    fill="#34393F"
                    d="M39,58c-7.4,1.6-15.1,0-21.2-4.5c-0.4-0.3-0.5-0.8-0.2-1.2c0.3-0.4,0.8-0.5,1.2-0.2c5.7,4.1,12.9,5.7,19.8,4.2
		c6.9-1.5,12.8-5.9,16.3-12c0.2-0.4,0.8-0.6,1.2-0.3c0.4,0.2,0.6,0.8,0.3,1.2C52.7,51.8,46.4,56.4,39,58z"
                />
            </g>
        </svg>
    )
}

const MemoSmileyPolish = React.memo(SmileyPolish)
export default MemoSmileyPolish
