import { Flex, SystemProps } from "@storyofams/react-ui"
import { Container } from "~components/common/Container"
import { Paragraph, Title } from "~components/common/Blocks"
import section from "~styles/theme/section"
import { Button } from "~components/common/Button"
import React, { ChangeEvent, FormEvent, useCallback, useState } from "react"
import Input from "~components/common/Input/Input"
import TextArea from "~components/common/TextArea/TextArea"
import {
    ErrorMessage,
    InlineInput,
} from "~components/common/Input/Input.styled"
import {
    ButtonContainer,
    InputWrapper,
    StyledErrorMessage,
    StyledForm,
    SuccessMessage,
} from "~components/common/Section/contactForm/ContactForm.styled"
import { FormattedMessage, useIntl } from "react-intl"
import CheckMark from "~components/common/Icon/library/CheckMark"

type ContactFormProps = {
    first?: boolean
    content: {
        title: any
        terms?: any
        toEmail: {
            cached_url?: string
            fieldtype?: string
            id?: string
            linktype?: string
            url?: string
            email?: string
        }
        fromEmail: string
        background_colour: "banana" | "tiger" | "fig" | "aloe" | "lapiz"
        lapiz_bold_colour?: boolean
        description?: any
        button_text?: string
    }
} & SystemProps
export default function ContactForm({
    first,
    content,
    ...props
}: ContactFormProps) {
    const intl = useIntl()
    const messages = intl.messages
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    })
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    })
    const [formError, setFormError] = useState(false)
    const [formSuccess, setFormSuccess] = useState(false)
    const { bg, button, bold, paragraph, link, title } =
        section[content.background_colour] || section.banana

    const validateForm = useCallback(() => {
        const newErrors = {
            firstName: "",
            lastName: "",
            email: "",
            message: "",
        }
        let isValid = true

        if (!formData.firstName) {
            newErrors.firstName = "First name is required"
            isValid = false
        }
        if (!formData.lastName) {
            newErrors.lastName = "Last name is required"
            isValid = false
        }
        if (!formData.email) {
            newErrors.email = "Email is required"
            isValid = false
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email address is invalid"
            isValid = false
        }
        if (!formData.message) {
            newErrors.message = "Message is required"
            isValid = false
        }

        setErrors(newErrors)
        return isValid
    }, [formData])

    const validateField = (name: string, value: string) => {
        let error = ""
        switch (name) {
            case "firstName":
                if (!value) error = "First name is required"
                break
            case "lastName":
                if (!value) error = "Last name is required"
                break
            case "email":
                if (!value) error = "Email is required"
                else if (!/\S+@\S+\.\S+/.test(value))
                    error = "Email address is invalid"
                break
            case "message":
                if (!value) error = "Message is required"
                break
            default:
                break
        }
        return error
    }

    const handleFormAction = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            if (formError) setFormError(false)
            if (formSuccess) setFormSuccess(false)
            if (!validateForm()) return

            setIsLoading(true)
            const { message, firstName, lastName, email, ...rest } = formData
            const name = `${firstName} ${lastName}`
            const toEmail = content.toEmail.email
            const fromEmail = content.fromEmail
            const nameLabel = intl.formatMessage({ id: "form.name" })
            const emailLabel = intl.formatMessage({ id: "form.email" })
            const messageLabel = intl.formatMessage({ id: "form.message" })
            const fromLabel = intl.formatMessage({ id: "form.from" })
            const subject = `Contact form submission from ${name}`
            const body = `${nameLabel}: ${name}\n${emailLabel}: ${email}\n${messageLabel}: ${message}`

            function throwError(message: string) {
                throw new Error("Something went wrong")
            }

            try {
                const response = await fetch("/api/resend", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        message: body,
                        nameLabel,
                        emailLabel,
                        messageLabel,
                        fromLabel,
                        fromEmail,
                        toEmail,
                        subject,
                    }),
                })

                if (response.status === 200) {
                    setFormSuccess(true)
                } else {
                    const { message } = await response.json()
                    throwError(message)
                }
            } catch (error) {
                setFormError(true)
            }

            setIsLoading(false)
        },
        [
            formData,
            content.fromEmail,
            content.toEmail.email,
            formError,
            formSuccess,
            validateForm,
            intl,
        ]
    )

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
        const error = validateField(name, value)
        setErrors({ ...errors, [name]: error })
    }

    return (
        <Container
            childProps={{
                pt: first ? [148, 280] : [10, 20],
            }}
            bgColor={bg}
            textAlign="center"
            {...props}
        >
            <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={["24px"]}
            >
                {!!content?.title?.content?.[0].content && (
                    <Title
                        text={content?.title}
                        boldColor={
                            !!content?.lapiz_bold_colour ? "lapiz" : bold
                        }
                        titleColor={title}
                        fontSize={[5, 8]}
                        h1={first}
                    />
                )}
                {!!content?.description?.content?.[0].content && (
                    <Paragraph
                        text={content?.description}
                        boldColor=""
                        linkColor={link}
                        paragraphColor={paragraph}
                    />
                )}
                <StyledForm onSubmit={handleFormAction}>
                    <InputWrapper>
                        <InlineInput>
                            <Input
                                type="text"
                                id="firstName"
                                name="firstName"
                                label={<FormattedMessage id="form.firstName" />}
                                value={formData.firstName}
                                onChange={handleChange}
                                aria-autocomplete="none"
                                error={errors.firstName}
                                icon={
                                    !errors.firstName &&
                                    !!formData.firstName ? (
                                        <CheckMark />
                                    ) : null
                                }
                            />

                            <Input
                                type="text"
                                id="lastName"
                                name="lastName"
                                label={<FormattedMessage id="form.lastName" />}
                                value={formData.lastName}
                                aria-autocomplete="none"
                                onChange={handleChange}
                                error={errors.lastName}
                                icon={
                                    !errors.lastName && !!formData.lastName ? (
                                        <CheckMark />
                                    ) : null
                                }
                            />
                        </InlineInput>

                        <Input
                            type="email"
                            id="email"
                            name="email"
                            label={<FormattedMessage id="form.email" />}
                            value={formData.email}
                            onChange={handleChange}
                            aria-autocomplete="none"
                            error={errors.email}
                            icon={
                                !errors.email && !!formData.email ? (
                                    <CheckMark />
                                ) : null
                            }
                        />

                        <TextArea
                            id="message"
                            name="message"
                            label={<FormattedMessage id="form.message" />}
                            placeholder="Dear Honest Cashew,"
                            value={formData.message}
                            onChange={handleChange}
                            error={errors.message}
                        />
                    </InputWrapper>
                    {formError && (
                        <StyledErrorMessage>
                            <FormattedMessage id="form.submitError" />
                        </StyledErrorMessage>
                    )}
                    {formSuccess && (
                        <SuccessMessage>
                            <FormattedMessage
                                id="form.submitSuccess"
                                values={{
                                    email: formData.email,
                                    name: formData.firstName,
                                }}
                            />
                        </SuccessMessage>
                    )}
                    <ButtonContainer>
                        {content?.button_text && (
                            <Button
                                variant={button as any}
                                type="submit"
                                isLoading={isLoading}
                            >
                                {content?.button_text}
                            </Button>
                        )}

                        {!!content?.terms?.content?.[0].content && (
                            <Paragraph
                                text={content?.terms}
                                boldColor=""
                                linkColor={link}
                                paragraphColor={paragraph}
                                fontSize={[2]}
                                lineHeight={["24px"]}
                            />
                        )}
                    </ButtonContainer>
                </StyledForm>
            </Flex>
        </Container>
    )
}
