import {
    DetailedHTMLProps,
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
    InputHTMLAttributes,
    ReactNode,
} from "react"
import {
    ErrorMessage,
    StyledIcon,
    StyledInput,
    StyledLabel,
} from "~components/common/Input/Input.styled"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}
export function Input({
    label,
    error,
    icon,
    type,
    ...rest
}: InputProps & {
    label?: ReactNode
    icon?: ReactNode
    error?: string | undefined
    type: "text" | "password" | "email" | "number"
}) {
    return (
        <StyledLabel>
            {label}
            <StyledInput type={type} {...rest} />
            {icon && <StyledIcon>{icon}</StyledIcon>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </StyledLabel>
    )
}

export default Input
