import * as React from "react"

function Tag() {
    return (
        <svg
            width="141"
            height="52"
            viewBox="0 0 141 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
        >
            <path
                d="M134.681 5.47985L135.261 4.66638L134.681 5.47992C135.243 5.88135 135.84 6.64236 136.423 7.8024C136.998 8.94672 137.52 10.3993 137.977 12.0863C138.891 15.459 139.516 19.668 139.81 23.9939C140.103 28.3175 140.061 32.7209 139.658 36.4769C139.248 40.2816 138.482 43.2603 137.434 44.8641L137.434 44.8643C137.436 44.8612 137.437 44.8597 137.437 44.8596C137.436 44.8585 137.364 44.9419 137.106 45.0796C136.839 45.2226 136.456 45.3805 135.943 45.5473C134.921 45.8802 133.492 46.214 131.693 46.5418C128.101 47.196 123.148 47.8062 117.262 48.3521C105.497 49.4433 90.0885 50.2706 74.5806 50.6913C59.0722 51.1119 43.4856 51.1252 31.3631 50.5916C25.2986 50.3246 20.1233 49.9217 16.2658 49.3689C14.3349 49.0921 12.7605 48.7815 11.5806 48.4403C10.3449 48.083 9.72154 47.7398 9.5001 47.5076C7.95352 45.8847 6.45665 43.2414 5.15553 39.9764C3.86172 36.7298 2.79098 32.9439 2.06027 29.1019C1.32939 25.2589 0.943731 21.3863 1.00666 17.9666C1.07002 14.5229 1.5871 11.6406 2.57976 9.70641C2.72242 9.42849 3.19752 8.98233 4.2923 8.45723C5.33873 7.95532 6.78352 7.4587 8.59969 6.97692C12.2265 6.01483 17.1953 5.14762 23.1012 4.39187C34.9044 2.88148 50.3316 1.83081 65.985 1.33134C81.637 0.831908 97.4889 0.884411 110.139 1.57523C116.466 1.92076 121.973 2.425 126.246 3.09547C128.383 3.4308 130.194 3.80501 131.635 4.21584C133.097 4.63243 134.1 5.06509 134.681 5.47985Z"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    )
}

const MemoTag = React.memo(Tag)
export default MemoTag
